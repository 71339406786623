import global from 'global';
import document from 'global/document';
import window from 'global/window';


// const history = null; //typeof window !== 'undefined' ? createBrowserHistory() : null;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

// import { Link } from 'gatsby';
import JsonTree from 'react-json-tree';
import Layout from 'components/layout';
import Box from 'components/box';
import Title from 'components/title';
import Affix from 'components/affix';

import TimeAgo from 'react-timeago'
// import ServiceThirdParties, {ServiceTechnology, ServiceThirdPartyTechnologies} from './serviceThirdParties';

import { JsonToTable } from 'react-json-to-table';

// import ServiceScore_DU from './modules/serviceScore';
// import ServiceScore_Safety from './modules/serviceScore';

// import {YouTube} from "components/youtube/youtube";
// import "components/youtube/youtube.css";
import {
  Tabs as Tabs2,
  Tab as Tab2,
  TabList,
  // DragTabList,
  // DragTab,
  PanelList,
  Panel,
  ExtraButton,
} from 'react-tabtab';

// import Plus from 'react-icons/lib/fa/plus';
// import { simpleSwitch } from '../../../helpers/move';
// import * as customStyle from 'react-tabtab/lib/themes/bootstrap';

import { Link } from 'gatsby';

// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import SEO from 'components/SEO';




  
// import DataGrid from 'react-data-grid';
// import 'react-data-grid/dist/react-data-grid.css';

const columns = [{ key: 'id', name: 'ID' }, { key: 'title', name: 'Title' }];

const rows = [{ id: 0, title: 'Example' }, { id: 1, title: 'Demo' }];

export function DataGridApp() {
  // return <DataGrid columns={columns} rows={rows} />;
  return <></>;
}

export default class ServiceTagData extends React.Component { 

    render() { 
      let data = [];
      if (!this.props.data) data = []; else data = this.props.data;
      // if (!Array.isArray(data)) data = []; else data = this.props.data;
  
      if (data == [] || data == undefined) return ;
      let permalink_path = "/categories/";
      if (this.props.permalink_path)  permalink_path = this.props.permalink_path;
      // console.log("ServiceTagData")
      return (
        <div className="container-d"> 
  
          <div className="row   pt-1  pb-1 d-none2">
            <div className="col-md-4">
              {/* <span className="font-weight-bold"> */}
              <h1 className="h4">{this.props.title}</h1>

                
              {/* </span> */}
            </div>
  
            <div className="col-md-8">
              { data.map(
                function(item, index) {
  
                    // console.log(item);
                    const title = item.title || item.title ||  item.url || item;
                    const url = item.url || item;
                    const description = title || item.description ||  item;
  
                  return (
                    <span key={index}> 
                      <Link
                      className="btn btn-xs btn-soft-secondary"
                        to={`/${permalink_path}/${
                          item.key
                        }`}
                        title={
                          description
                        }
                      > 
                          {title} 
                      </Link>
                      &nbsp;
                    </span>
                  );
                }
              )}
            </div>
          </div>
  
        </div>
      );
    }
  }
  
  ServiceTagData.propTypes = {
    data: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    // description: PropTypes.string.isRequired,
    // fields: PropTypes.object.isRequired,
    // parameters: PropTypes.object.isRequired,
    // service: PropTypes.object.isRequired,
    // modelClass: PropTypes.string.isRequired
  };



export const StatusIndicatorOld = item => (
  <span>
    <span
      style={{
        color:
          item.value === 'DANGER'
            ? '#ff2e00'
            : item.value === 'CAUTION'
            ? '#ffbf00'
            : '#ff2e00',
        transition: 'all .5s ease',
      }}
    >
      &#x25cf;
    </span>{' '}
    {item.value === 'DANGER'
      ? 'DANGER'
      : item.value === 'CAUTION'
      ? `CAUTION`
      : '?'}
  </span>
);

export const StatusIndicatorBoolean = item => (
  <span>
    <span
      style={{
        color:
          item.value === 'DANGER'
            ? '#ff2e00'
            : item.value === 'CAUTION'
            ? '#ffbf00'
            : '#AAA',
        transition: 'all .5s ease',
      }}
    >
      &#x25cf;
    </span>{' '}
    {/* {item.value === 'DANGER'
      ? 'YES'
      : item.value === 'CAUTION'
      ? `NO`
      : '?'}  */}
  </span>
);

export function JsonToTableApp(data) {
  // ===================== //
  // JSON Object
  // ===================== //
  var myJson = {};
  if (!data)
    myJson = {
      Student: { name: 'Jack', email: 'jack@xyz.com' },
      'Student id': 888,
      Sponsors: [
        { name: 'john', email: 'john@@xyz.com' },
        { name: 'jane', email: 'jane@@xyz.com' },
      ],
    };
  else myJson = data;

  // console.log(data);

  return (
    <div className="App">
      {/* ===================== */}
      {/* HOW TO USE IT         */}
      {/* ===================== */}
      <JsonToTable json={myJson} />
      {/* ===================== */}
    </div>
  );
}

export class ServiceTabularData extends React.Component {
  //new master class to take any input data from record and match with model / tabular definition lists -- TODO - apply this to all existing components as a new super reusable component and to also make thigns parameterizable and also derived from the db
    // TODO - take this master class and apply it to existing models and eliminate custom modules if possible from ./modules folder so it's mostly data driven and parameter driven - adding any new parameters needed to parameterize the reusable component here


  getParameterModule(key) {
    
    // console.log('getParameterModule - '+this.props.title);
    // console.log(this.props.parameters);

    var parametersConfigurations = this.props.parameters

    // loop over values
    for (let obj of Object.entries(parametersConfigurations)) {
      // alert(value); // John, then 30
      
      // swal('ServiceReport!', obj['key']);
      // console.log(obj)
      if (obj['key']==key) {
        // swal('ServiceReport!', obj);
        return obj;
      }
    }

    return null
  }

  render() {

    // console.log('ServiceTabularData');
    // console.log(this.props.parameters);
    var modelClass = 'serviceOnlineTrustAllianceAnalysis';

    if (this.props.modelClass) modelClass = this.props.modelClass;

    // var modelPath =
      // '../../../shared/dpt-shared-config/config/' + modelClass + '.config.js';

    // var moduleRequirePath = 'shared-dpt-modules';

    // "C:\Users\omar\Documents\DEV\dpt\stack-boilerplate\client\client\shared\dpt-shared-config\config\serviceOnlineTrustAllianceAnalysis.config.js"
    // "shared\dpt-shared-config\config\serviceDPTTrustScore.config.js"

    // const configurationData = require(modulePath); //.require("/config/" + modelClass + ".config.js");

    // console.log(configurationData);

    // require.context(directory, useSubdirectories = true, regExp = /^\.\/.*$/, mode = 'sync')
    // require.context('../', true, /\.stories\.js$/);
    // a context with all files in the parent folder and descending folders ending with `.stories.js`.
    // console.log(modelPath);
    // var model = require(modelPath);

    // var model = require(modelPath);

    var service = {};
    var title = {};
    var description = {};
    var data = {};
    var model = {};

    if (this.props.service) service = this.props.service;

    var name = '';

    if (service) name = service.name;

    if (this.props.data) data = this.props.data;

    if (this.props.title) title = this.props.title;

    if (this.props.description) description = this.props.description;

    if (this.props.parameters)   {
      model = this.getParameterModule(modelClass)
      console.log(model)
    }

    // alert(data);
    // console.log("ServiceTabularData - " + title);

    // console.log(data);

    if (data == [] || this.props=='') {
      
      return;

    } else{
      // if (!Array.is_array(data) {

      // }
    }



    var dataRender = null;
    var newArrModel = [];
    var newArrValues = [];

    if (model==undefined && this.props.items!=undefined)
      dataRender = { data: this.props.items}
    else {
    if (model != {} && model!=null) {
      //go through the template data rows and match with the values and display the merged value (assuming schema / keys are same)
       
        model.data.map(function(item, key) {
          var obj = {};
          obj[item.key] = item;

          newArrModel[key] = obj;

          try {
            // alert( JSON.stringify(item));
            newArrValues[key] = data[item.key];
          } catch (ex) {
            newArrValues[key] = 0;
          }
        });
        dataRender = model;
       

    } else {
      var theData = {};
      theData = data; //{data: data}
      dataRender = theData;
    }
  }
    // clean the dataRender object of key, url, slug, date time etc

    // dataRender.map(function(item, key) { 

    //   try {
    //     // alert( JSON.stringify(item));
    //     if (key == "slug" )
    //       delete dataRender[key]
    //   } catch (ex) {
        
    //   }
    // });

    // console.log('dataRender');
    // console.log(dataRender);
    // //go through the template data rows and match with the values and display the merged value (assuming schema / keys are same)
    // privacyAnalysis.data.map(function(item, key) {
    //   var obj = {};
    //   obj[item.key] = item;

    //   newArrValues[key]=obj;
    //   // newArrValues.push( item );
    // });

    return (
      <div>
        {' '}
        <h4>{title}</h4>{' '}
        {/*         
        <p style={{ marginTop: '15px' }}>
          The following information relates to the privacy, transparency and disclosures of {' '}
          {service.name}.
        </p> */}
        <p style={{ marginTop: '15px' }}>
          {description}
          {/*     The model consists
          of 28 parameters 
           
          , based on cybersecurity "best practices" mapped to Level
          1-Level 5 overall rating .*/}
        </p>
        {/* <!-- partial:partials/service_provider_report_analysis.html --> */}
        {/* <!-- 
    
    <div className="p-2">
        &nbsp;&nbsp; Privacy Trust Score&nbsp;
        <span className="score-badge" data-container="body" data-toggle="popover" data-placement="bottom" data-content="<em>Privacy Trust Score</em> - <a href='/privacy_trust_score.html'>Learn more</a>"
            data-html="true" title="">53.30</span>
    </div>
    <div className="p-2">&nbsp;&nbsp; Personalized Privacy Trust Score&nbsp;
        <span className="personal-score-badge" data-container="body" data-toggle="popover" data-placement="bottom" data-content="<em>Personalized Privacy Trust Score</em> - <a href='/privacy_trust_score_personalized.html'>Learn more</a>"
            data-html="true" title="">33.67</span>
    </div>
    <div className="p-2">
        <a href="#" data-toggle="modal" data-target="#privacyTrustModal">What is the Privacy Trust Score?</a>
    </div> 
</div>
            --> */}
        {/* <a href="#" onclick="toSay('ppa');" className="d-none-future">
                TTS
              </a> */}

              
        <JsonToTableApp data={dataRender} />
        <table
          // id="ppa"
          className="table table-hover table-responsive table-sm2"
        >
          <thead className="">
            <tr>
              <th />
              <th>Criteria</th>
              <th>Status</th>
              {/* <th className="hidden-xs ">Meaning</th> */}
              <th className=" d-none">Importance to me</th>
              <th className=" d-none">Score</th>
            </tr>
          </thead>
          <tbody>
            {/* <ChildElementRow  data={this.props.data.service.privacyAnalysis}  propFromProps={this.props.aParentProp} propLiteral="A literal" /> */}
            {/* <RenderTableRow data={this.props.data}/> */}
            {/* <Item data={this.props.data}/> */}
            {/* <ChildElementRows
                    data={this.props.data.service.privacyAnalysis}
                  /> */}
            {/* 
                  <ChildElementRow
                    data={privacyAnalysisModel.data}
                   
                  /> */}
            { dataRender.data   ? 
            (dataRender.data.map(function(item, key) {
              console.log('dataRender loop - '+key);   
              console.log(item);
              console.log('dataRender loop - newArrValues - '+item.key);   
              console.log(newArrValues[item.key]);
              let tabletitle = item.title || item.url || item.key;
              const icon = item.icon;
              const description = item.description;
              const score = 0;
              

              if (newArrValues[key])
                newArrValues[key].score;

              // const status =
              //   newArrValues[key].status == 'DANGER'
              //     ? 'CAUTION'
              //     : newArrValues[key].status;
              const indicator = '';

              if (newArrValues[item.key]) {
                newArrValues[item.key].indicator;
                tabletitle = newArrValues[item.key].title;
              }

              if (item.status)
                item.status = item.status.toUpperCase();

              item.indicator = indicator;

              // myitem.key=key+"-"+randomString({
              //   length: 8,
              //   numeric: true,
              //   letters: true,
              //   special: false,
              //   exclude: ['a', 'b', '1']
              // });

              // }
              return (
                <tr key={item.key}>
                  <td>
                    {/* <FontAwesomeIcon icon={item.icon} /> */}
                    <i className={`fab fas fa-2x fa-${icon}`} />
                    {/* {currentIndicator} - {currentScore} - {currentStatus} */}
                  </td>
                  <td>
                    {/* <h5> */}
                    <span className="badge2 badge-dark2 text-primary font-weight-bold">{tabletitle}</span>{' '}
                    {item.more ? <More item={item} /> : null}
                    {/* </h5> */}
                  </td>
                  <td>
                    {/* <a href="#" className={`badge badge-${status}`}>
                      {indicator}
                    </a> */}

                    <StatusIndicator item={item} />
                  </td>
                  {/* <td>
                    {description}
                    
                  </td> */}
                  <td className="d-none">
                    <input
                      type="range"
                      min="10"
                      max="100"
                      step="10"
                      value="100"
                      data-orientation="vertical"
                    />
                  </td>
                  <td className=" d-none">
                    <span className="badge badge-pill badge-dark">{score}</span>
                  </td>
                </tr>
              );
            })) : <></>}
           
            {/* <ChildElementRows
                    data={privacyAnalysisModel.data}
                    values={privacyAnalysis}
                  /> */}
            {/* {privacyAnalysisModel} */}

            {/* {{#if privacyAnalysis.data}} */}
            {/* {{#each privacyAnalysis.data}} */}
            {/* <ChildElementRow data={this.props.data}  propFromProps={this.props.aParentProp} propLiteral="A literal" /> */}
          </tbody>
        </table>
        {/*   
        <div className="container">
        <small>Module - Personal Data Utilization Privacy Analysis</small>
          <JsonTree data={privacyAnalysisModel} />{' '}
        </div> 
        <div className="container">
        <small>Data - {service.name}</small>
          <JsonTree data={this.props.data} />{' '}
        </div>   */}
      </div>
    );
  }
}

ServiceTabularData.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.object.isRequired,
  description: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  parameters: PropTypes.object.isRequired,
  service: PropTypes.object.isRequired,
  modelClass: PropTypes.object.isRequired
};


export class ServiceDescriptionData extends React.Component { 

  render() { 

    if (!this.props.data) return false
    // console.log("ServiceTagData")
    return (
      <div className="container-d"> 

        <div className="row   pt-1  pb-1 d-none2">
          <div className="col-md-4">
            {/* <span className="font-weight-bold"> */}
            
            <h1 className="h4">{this.props.title}</h1> 
            {/* </span> */}
          </div>

          <div className="col-md-8"> {this.props.data}
           
          </div>
        </div>

      </div>
    );
  }
}

ServiceDescriptionData.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.object.isRequired,
  description: PropTypes.object.isRequired
};




export class ServiceDescriptionDataDate extends React.Component { 

  render() { 

    if (!this.props.data) return false
    // console.log("ServiceTagData")
    return (
      <div className="container-d"> 

        <div className="row   pt-1  pb-1 d-none2">
          <div className="col-md-4">
            {/* <span className="font-weight-bold"> */}
            
            <h1 className="h4">{this.props.title}</h1> 
            {/* </span> */}
          </div>

          <div className="col-md-8"> <TimeAgo date={this.props.data} /> - {formatDate(this.props.data)}
           
          </div>
        </div>

      </div>
    );
  }
}

ServiceDescriptionDataDate.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.object.isRequired,
  description: PropTypes.object.isRequired
};








const StatusIndicator4 = ({ item }) => {
  var colorC =
    item.status === 'DANGER'
      ? '#ff2e00'
      : item.status === 'CAUTION'
      ? '#ffbf00'
      : '#28a745';
  // console.log(JSON.stringify(item), key);
  var score = item.score;
  return (
    <span>
      {/* <div style={{ textAlign: 'left' }}> */}
      {/* <h2 style={{ color: 'black', margin: '10px', fontSize: '14px' }}>
        Risk Score
      </h2> */}{' '}
      <span
        style={{
          color: colorC,
          transition: 'all .5s ease',
        }}
      >
        &#x25cf;
      </span>{' '}
      <LinearGauge
        colorScheme={['rgba(28, 107, 86, 0.5)', colorC]}
        height={30}
        width={100}
        series={<LinearGaugeSeries colorScheme={[colorC]} />}
        // bar={}
        data={{
          key: 'Risk',
          data: score,
        }} //:80
      />
      {/* <DiscreteLegend
    orientation="horizontal"
    entries={mitreData.map((v, i) => (
      <DiscreteLegendEntry
        key={v.key}
        style={{ padding: '0 3px' }}
        symbol={<Icon fill={colorScheme[i]} />}
        label={`${v.data}`}
        color={colorScheme[i]}
        orientation="horizontal"
      />
    ))}
  /> */}
      {/* </div> */}
      {/* {risk ? (
                    <span className="badge badge-pill badge-danger">HIGH RISK</span>
                  ) : null} */}
      {/* {item.value === 'DANGER'
      ? 'DANGER'
      : item.value === 'CAUTION'
      ? `CAUTION`
      : '?'} */}
    </span>
  );
};
 

export const StatusIndicator = ({ item }) => {

  if (item.score > 7) item.status = 'OK';
  if (item.score > 3 && item.score <= 7) item.status = 'CAUTION';
  if (item.score > 0 && item.score <= 3) item.status = 'DANGER';
  if (item.score <= 0 || item.score == '' || item.score == undefined) { item.status = 'UNKNOWN';  }

  var colorC = ' ';//item.status === 'DANGER' ? '#ff2e00' : item.status === 'CAUTION'  ? '#ffbf00' : '#28a745';

  switch(item.status) {
    case 'DANGER':
      colorC = '#ff2e00';
      // code block
      break;
    case 'CAUTION':
      colorC = '#ffbf00';
      // code block
      break;
    case 'OK':
      colorC = '#28a745';
      // code block
      break;
    case 'UNKNOWN':
      colorC = 'gray';
      // code block
      break;
    default:
      // code block
  }
  // console.log(JSON.stringify(item));

  var score = item.score || 0;
  return (
    <div>
      {/* <div style={{ textAlign: 'left' }}> */}
      {/* <h2 style={{ color: 'black', margin: '10px', fontSize: '14px' }}>
        Risk Score
      </h2> */}
      <span
        style={{
          color: colorC,
          transition: 'all .5s ease',
        }}
      >
        &#x25cf;
      </span>
      {/* </div> */}
      {/* {risk ? (
                    <span className="badge badge-pill badge-danger">HIGH RISK</span>
                  ) : null} */}
      {/* {item.value === 'DANGER'
      ? 'DANGER'
      : item.value === 'CAUTION'
      ? `CAUTION`
      : '?'} */}
    </div>
  );
};


export const StatusScorePrimary = ({ item }) => {
 

  if (item.score > 70) item.status = 'OK';
  if (item.score > 30 && item.score <= 70) item.status = 'CAUTION';
  if (item.score > 0 && item.score <= 30) item.status = 'DANGER';
  if (item.score <= 0 || item.score == '' || item.score == undefined) { item.status = 'UNKNOWN';   }

  var colorC = ' ';//item.status === 'DANGER' ? '#ff2e00' : item.status === 'CAUTION'  ? '#ffbf00' : '#28a745';

  switch(item.status) {
    case 'DANGER':
      colorC = '#ff2e00';
      // code block
      break;
    case 'CAUTION':
      colorC = '#ffbf00';
      // code block
      break;
    case 'OK':
      colorC = '#28a745';
      // code block
      break;
    case 'UNKNOWN':
      colorC = 'gray';
      // code block
      break;
    default:
      // code block
  }
  // console.log(JSON.stringify(item));
  var score = item.score || '-';
// var score = item.score || '?';
return (
    <span className=" badge badge  badge-default "
      style={{ 
        fontSize: '25px',
        backgroundColor: colorC,
        color: 'white',
        transition: 'all .5s ease',
      }}
    >
       {score}
    </span> 
);
};

export const StatusScore = ({ item }) => {
 

  if (item.score > 7) item.status = 'OK';
  if (item.score > 3 && item.score <= 7) item.status = 'CAUTION';
  if (item.score > 0 && item.score <= 3) item.status = 'DANGER';
  if (item.score <= 0 || item.score == '' || item.score == undefined) { item.status = 'UNKNOWN';   }

  var colorC = ' ';//item.status === 'DANGER' ? '#ff2e00' : item.status === 'CAUTION'  ? '#ffbf00' : '#28a745';

  switch(item.status) {
    case 'DANGER':
      colorC = '#ff2e00';
      // code block
      break;
    case 'CAUTION':
      colorC = '#ffbf00';
      // code block
      break;
    case 'OK':
      colorC = '#28a745';
      // code block
      break;
    case 'UNKNOWN':
      colorC = 'gray';
      // code block
      break;
    default:
      // code block
  }
  // console.log(JSON.stringify(item));
  var score = item.score || '-';
// var score = item.score || '?';
return ( 
    <span className=" badge badge  badge-default "
      style={{ 
        fontSize: '25px',
        backgroundColor: colorC,
        color: 'white',
        transition: 'all .5s ease',
      }}
    >
       {score}
    </span>  
);
};

export const StatusIndicatorGauge = ({ item }) => {

  if (item.score > 7) item.status = 'OK';
  if (item.score > 3 && item.score <= 7) item.status = 'CAUTION';
  if (item.score > 0 && item.score <= 3) item.status = 'DANGER';
  if (item.score <= 0 || item.score == '' || item.score == undefined) { item.status = 'UNKNOWN';   }

  var colorC = ' ';//item.status === 'DANGER' ? '#ff2e00' : item.status === 'CAUTION'  ? '#ffbf00' : '#28a745';

  switch(item.status) {
    case 'DANGER':
      colorC = '#ff2e00';
      // code block
      break;
    case 'CAUTION':
      colorC = '#ffbf00';
      // code block
      break;
    case 'OK':
      colorC = '#28a745';
      // code block
      break;
    case 'UNKNOWN':
      colorC = 'gray';
      // code block
      break;
    default:
      // code block
  }
  // console.log(JSON.stringify(item));
  var score = item.score || 0;
  return (
    <>
      {/* <div style={{ textAlign: 'left' }}> */}
      {/* <h2 style={{ color: 'black', margin: '10px', fontSize: '14px' }}>
        Risk Score
      </h2> */}
      {/* <span
        style={{
          color: colorC,
          transition: 'all .5s ease',
        }}
      >
        &#x25cf;
      </span> */}
      <LinearGauge
        animated={false}
        colorScheme={['rgba(28, 107, 86, 0.5)', colorC]}
        height={30}
        width={80}
        series={<LinearGaugeSeries colorScheme={[colorC]} />}
        // bar={}
        data={{
          key: 'Risk',
          data: score * 10,
        }} //:80
      />
      {/* <DiscreteLegend
    orientation="horizontal"
    entries={mitreData.map((v, i) => (
      <DiscreteLegendEntry
        key={v.key}
        style={{ padding: '0 3px' }}
        symbol={<Icon fill={colorScheme[i]} />}
        label={`${v.data}`}
        color={colorScheme[i]}
        orientation="horizontal"
      />
    ))}
  /> */}
      {/* </div> */}
      {/* {risk ? (
                    <span className="badge badge-pill badge-danger">HIGH RISK</span>
                  ) : null} */}
      {/* {item.value === 'DANGER'
      ? 'DANGER'
      : item.value === 'CAUTION'
      ? `CAUTION`
      : '?'} */}
    </>
  );
};


export const StatusIndicatorGaugePrimary = ({ item }) => {

  if (item.score > 70) item.status = 'OK';
  if (item.score > 30 && item.score <= 70) item.status = 'CAUTION';
  if (item.score > 0 && item.score <= 30) item.status = 'DANGER';
  if (item.score <= 0 || item.score == '' || item.score == undefined) { item.status = 'UNKNOWN';   }

  var colorC = ' ';//item.status === 'DANGER' ? '#ff2e00' : item.status === 'CAUTION'  ? '#ffbf00' : '#28a745';

  switch(item.status) {
    case 'DANGER':
      colorC = '#ff2e00';
      // code block
      break;
    case 'CAUTION':
      colorC = '#ffbf00';
      // code block
      break;
    case 'OK':
      colorC = '#28a745';
      // code block
      break;
    case 'UNKNOWN':
      colorC = 'gray';
      // code block
      break;
    default:
      // code block
  }
  // console.log(JSON.stringify(item));
  var score = item.score || 0;
  return (
    <>
      {/* <div style={{ textAlign: 'left' }}> */}
      {/* <h2 style={{ color: 'black', margin: '10px', fontSize: '14px' }}>
        Risk Score
      </h2> */}
      {/* <span
        style={{
          color: colorC,
          transition: 'all .5s ease',
        }}
      >
        &#x25cf;
      </span> */}
      <LinearGauge
        animated={false}
        colorScheme={['rgba(28, 107, 86, 0.5)', colorC]}
        height={30}
        width={80}
        series={<LinearGaugeSeries colorScheme={[colorC]} />}
        // bar={}
        data={{
          key: 'Risk',
          data: score * 10,
        }} //:80
      />
      {/* <DiscreteLegend
    orientation="horizontal"
    entries={mitreData.map((v, i) => (
      <DiscreteLegendEntry
        key={v.key}
        style={{ padding: '0 3px' }}
        symbol={<Icon fill={colorScheme[i]} />}
        label={`${v.data}`}
        color={colorScheme[i]}
        orientation="horizontal"
      />
    ))}
  /> */}
      {/* </div> */}
      {/* {risk ? (
                    <span className="badge badge-pill badge-danger">HIGH RISK</span>
                  ) : null} */}
      {/* {item.value === 'DANGER'
      ? 'DANGER'
      : item.value === 'CAUTION'
      ? `CAUTION`
      : '?'} */}
    </>
  );
};

const StatusIndicator2 = item => (
  <span>
    <span
      style={{
        color:
          item.value === 'DANGER'
            ? '#ff2e00'
            : item.value === 'CAUTION'
            ? '#ffbf00'
            : '#AAA',
        transition: 'all .5s ease',
      }}
    >
      &#x25cf;
    </span>{' '}
    {/* {item.value === 'DANGER'
      ? 'DANGER'
      : item.value === 'CAUTION'
      ? `CAUTION`
      : '?'} */}
  </span>
);

import { LinearGauge, LinearGaugeSeries } from 'reaviz';
import Radar from 'react-d3-radar';

const mitreData = [
  {
    key: 'Initial Access',
    data: 2,
  },
  {
    key: 'Execution',
    data: 5,
  },
  {
    key: 'Persistence',
    data: 2,
  },
  {
    key: 'Privilege Escalation',
    data: 14,
  },
  {
    key: 'Defense Evasion',
    data: 12,
  },
  {
    key: 'Credential Access',
    data: 10,
  },
  {
    key: 'Discovery',
    data: 12,
  },
  {
    key: 'Lateral Movement',
    data: 5,
  },
  {
    key: 'Collection',
    data: 8,
  },
  {
    key: 'Exfiltration',
    data: 6,
  },
  {
    key: 'Command and Control',
    data: 9,
  },
  {
    key: 'Unknown/Other',
    data: 0,
  },
];
/* 
const AppTabs = props => {
  return (
    <div>
      <h3 />
      <TabContainer id="dpt-data-security" defaultActiveKey="certifications">
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="certifications">
                  Documents &amp; Policies
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="nist">
                  NIST Cybersecurity Framework
                </Nav.Link>
              </Nav.Item>
           <Nav.Item>
                              <Nav.Link eventKey="mitre">
                              MITRE ATTACK Matrix Framework 
                              </Nav.Link>
                            </Nav.Item> 
              <Nav.Item>
                <Nav.Link eventKey="cybersecurity">
                  Cybersecurity Maturity
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="antiphishing">
                  Anti-phishing Defense
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="domain">Domain Security</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="thirdparty">Third Party Risk</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="ddos">DDOS Defenses</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="infrastructure">Infrastructure</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="map">Map</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <TabContent>
              <TabPane eventKey="certifications">
                <h4>Certifications &amp; Compliance</h4>
                <ServiceCertifications
                  data={this.props.data}
                  values={service.privacyAnalysis}
                />
              </TabPane>
            <TabPane eventKey="nist">
                              <h4>NIST Cybersecurity Framework</h4>

                              <ServiceDataSecurity
                                data={this.props.data}
                                values={service.privacyAnalysis}
                              />
                            </TabPane> 
             <TabPane eventKey="mitre">
                              <h4>MITRE Attack Matrix</h4>
                              <p>MITRE ATT&CK™
                              https://attack.mitre.org/

                              </p>
                                                            
                              <div style={{ width: '465px', textAlign: 'center' }}>
                                <h2 style={{ color: 'white', margin: 0 }}>MIRTE Attack</h2>
                                <LinearGauge
                                  height={30}
                                  data={mitreData}
                                  series={<LinearGaugeSeries 
                                    colorScheme={colorScheme} 
                                    />}
                                />
                                <DiscreteLegend
                                  orientation="horizontal"
                                  entries={mitreData.map((v, i) => (
                                    <DiscreteLegendEntry
                                      key={v.key}
                                      style={{ padding: '0 3px' }}
                                      symbol={<Icon fill={colorScheme[i]} />}
                                      label={`${v.data}`}
                                      color={colorScheme[i]}
                                      orientation="horizontal"
                                    />
                                  ))}
                                />
                              </div>
                               
                            </TabPane> 
              <TabPane eventKey="cybersecurity">
                <h4>Cybersecurity Maturity</h4>

                <ServiceDataSecurity
                  data={this.props.data}
                  values={service.privacyAnalysis}
                />
              </TabPane>
              <TabPane eventKey="antiphishing">
                <h4>Anti-phishing attack defenses</h4>
                <ServicePhishingDefenses data={this.props.data} />
              </TabPane>
              <TabPane eventKey="thirdparty">
                <h4>Third Party Risk</h4>
                <ServiceThirdParties
                  data={this.props.data}
                  domain_data = {this.props.domain_data}
                  service_data = {this.props.service_data}
                  thirdpartyweb_data = {this.props.thirdpartyweb_data}
                  values={service.privacyAnalysis}
                />

                  <ServiceThirdPartyTechnologies
                  data={this.props.data}
                  domain_data = {this.props.domain_data}
                  service_data = {this.props.service_data}
                  thirdpartyweb_data = {this.props.thirdpartyweb_data}
                  values={service.privacyAnalysis}
                />
                
                <ServiceThirdPartyAnalysis
                  data={this.props.data}
                  domain_data = {this.props.domain_data}
                  service_data = {this.props.service_data}
                  thirdpartyweb_data = {this.props.thirdpartyweb_data}
                  values={service.privacyAnalysis}
                />
                <ServiceTechnology
                  data={this.props.data}
                  domain_data = {this.props.domain_data}
                  service_data = {this.props.service_data}
                  thirdpartyweb_data = {this.props.thirdpartyweb_data}
                  values={service.privacyAnalysis}
                />

              </TabPane>{' '}
              <TabPane eventKey="domain">
                <h4>Domain</h4>
                <ServiceDomain
                  data={this.props.data}
                  values={service.privacyAnalysis}
                />
              </TabPane>
              <TabPane eventKey="infrastructure">
                <h4>Infrastructure</h4>
                <ServiceInfrastructure
                  data={this.props.data}
                  values={service.privacyAnalysis}
                />
              </TabPane>
              <TabPane eventKey="ddos" />
              <TabPane eventKey="map">
                <h4>Map</h4>
                <ServiceMaps
                  data={this.props.data}
                  domain_data = {this.props.domain_data}
                  service_data = {this.props.service_data}
                  thirdpartyweb_data = {this.props.thirdpartyweb_data}
                  values={service.privacyAnalysis} 
                />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </TabContainer>
    </div>
  );
};

 */

export const TimelineItemRow = ({ item }) => (
  <TimelineItem key="001" dateText={item.date} style={{ color: '#e86971' }}>
    <h5>
      <a href={item.url}>
        <b>{item.title}</b>
      </a>
    </h5>
    <p>
      <a href={item.url}>{item.content}</a>
    </p>
  </TimelineItem>
);

const TimelineItemRow2 = ({ service, item }) => { 
  
  item.url = "/service/"+service.id+"/news/"+item.url;
  return (
  <li>
   <Link  to={item.url}>
      <b>{item.title}</b>
      </Link>
    <a href="#" className="float-right">
      {item.date}
    </a>
    <p>
      <Link to={item.url}>{item.content}</Link>
    </p>
  </li>
)};

export const TimelineItemRowTableRow = ({ service, item, prefix = '/news/', documentViewLinks=false }) => { 
  let url = '';
  
  if (item.url)
    url = item.url;
    
    if (item.link)
      url = item.link; 
  
    item.url = "/service/"+service.id+prefix+encodeURIComponent(url);
    item.urlMachine = "/service/"+service.id+"/machine/"+encodeURIComponent(url);
    item.urlDocument = "/service/"+service.id+"/document/"+encodeURIComponent(url);

    let itemCategory;
    if (item.category)
      itemCategory = <p>
      <Link to={"/categories/"+item.category}>{item.category}</Link>
    </p>;
    else itemCategory = <p>
      
  </p>;

if (item.key == undefined || item.key == '' ) item.key= "general"

  return (

     
    <tr key={item.url}>
      {/* <td /> */}
      <td>

      <StatusIndicator item={item} />

      </td>
      <td className="">
   {/* <Link  to={item.url}>
      <b>{item.title}</b>
      </Link> */}
      
      <Link
                    // to={`service/${service.id}/${item.key}/embed`}
                    to={item.url}
                    title={`View an embedded view of this information related to ${
                      service.name
                    }`}
                  >
                    {' '}
                    {/* <span className="text-muted2 text-small2 badge badge  badge-default"> */}
                      
                      <span className="text-muted2 text-small2 badge2 badge2  badge-default22 font-weight-bold">{item.title}{' '} </span>

                    {/* </span> */}
                  </Link>
      
       {item.more? <More item={item} description={item.description} /> : null}
  
    {/* <p> */}
      <Link to={item.url}>{item.content}</Link>
    {/* </p>  */}
    
    
    {documentViewLinks == true ? (
              <>
            {/* {' '} */}
                  {process.env.NODE_ENV=="development" ? (
      <> 
       
       <Link
                    to={item.urlMachine}
                    // href="#"
                    // data-toggle="modal"
                    // data-target="#myPrivacyAnalysisModal"
                    styleC="color: blueviolet"
                    title={`View an AI machine vision view of this information related to ${
                      service.name
                    }`}
                  >
                    <span className=" badge badge  badge-primary">AI </span>
                  </Link>
 
      </>
    ) : (
      <> 
      </>
    )}
                  <Link
                    to={item.urlDocument}
                    title={`Visit this page related to  ${service.name}`}
                  >
                    {' '}
                    <span className="text-muted2 text-small2 badge badge  badge-primary">
                    <i className="fas fa-external-link-alt"></i>{' '}
                    </span>
                  </Link>{' '}
             
             
            {item.description? <More item={item} description={description} /> : null}
              </>
            ) :   <span className="text-muted2 text-small2 badge2  badge-default2 font-weight-bold">{' '} </span>        }
            
    </td>
      {/* <td className="text-center">   <a href="#" className="float-right">
      {item.date}
    </a></td> */}
      <td className="">
   {itemCategory}  </td> 
    </tr>  
)};

 

export function getDocumentLinkfromProfile(service, key) {
  let r = '';
  if (service.documents)
    service.documents.forEach(element => {
      // console.log("getDocumentLinkfromProfile", key)
      if (element.key === key) {
        // console.log("getDocumentLinkfromProfile", key, 'found');
        // console.log(element.url);
        r = element.url;
      }
    });
  return r;
}

export function getValueFromKV(list, key) {
  let r = '';
  list.forEach(element => {
    // console.log("getDocumentLinkfromProfile", key)
    if (element.key === key) {
      // console.log("getDocumentLinkfromProfile", key, 'found');
      // console.log(element.url);
      r = element;
    }
  });
  return r;
}

// import React, { Component } from 'react';
// import LineChart from 'react-linechart';
// import 'react-linechart/dist/styles.css';

// export class ServiceDPTTrustScoreLineGraph extends React.Component {
//   // constructor(props) {
//   //   super(props);
//   //   this.state = { value: '' };
//   // }

//   render() {
//     const data = [
//       {
//         color: 'steelblue',
//         points: [
//           { x: 1, y: 50 },
//           { x: 2, y: 50 },
//           { x: 3, y: 50 },
//           { x: 4, y: 60 },
//           { x: 5, y: 70 },
//           { x: 6, y: 70 },
//           { x: 7, y: 50 },
//           { x: 8, y: 50 },
//           { x: 9, y: 50 },
//           { x: 10, y: 60 },
//           { x: 11, y: 70 },
//           { x: 12, y: 70 },
//         ],
//       },
//     ];
//     return (
//       <div>
//         <div className="App">
//           {/* <h1>My First LineChart</h1> */}
//           <LineChart
//             width={'100%'}
//             height={400}
//             data={data}
//             // isDate={true}
//           />
//         </div>
//       </div>
//     );
//   }
// }

// ServiceDPTTrustScoreLineGraph.propTypes = {
//   data: PropTypes.object.isRequired,
// };

// export ServiceDPTTrustScoreLineGraph;

/* 

var CanvasJSReact = require('./canvasjs.react');
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
 
class ServiceDPTTrustScoreLineGraph2 extends Component {	
	render() {
		const options = {
			animationEnabled: true,
			theme: "light2",
			title: {
				text: "Growth of Photovoltaics"
			},
			axisY: {
				title: "Capacity (in MWp)",
				logarithmic: true
			},
			data: [{
				type: "spline",
				showInLegend: true,
				legendText: "MWp = one megawatt peak",
				dataPoints: [
				  { x: new Date(2001, 0), y: 1615},
				  { x: new Date(2002, 0), y: 2069},
				  { x: new Date(2003, 0), y: 2635},
				  { x: new Date(2004, 0), y: 3723},
				  { x: new Date(2005, 0), y: 5112},
				  { x: new Date(2006, 0), y: 6660},
				  { x: new Date(2007, 0), y: 9183},
				  { x: new Date(2008, 0), y: 15844},
				  { x: new Date(2009, 0), y: 23185},
				  { x: new Date(2010, 0), y: 40336},
				  { x: new Date(2011, 0), y: 70469},
				  { x: new Date(2012, 0), y: 100504},
				  { x: new Date(2013, 0), y: 138856},
				  { x: new Date(2014, 0), y: 178391},
				  { x: new Date(2015, 0), y: 229300},
				  { x: new Date(2016, 0), y: 302300},
				  { x: new Date(2017, 0), y: 405000}   
				]
			}]
		}
		
		return (
		<div>
			<CanvasJSChart options = {options} 
        
      
			/>
  
      </div>
		);
	}
}
  */ 

/* eslint-disable react/no-multi-comp */
import { PureComponent } from 'react';
import {
  
  ResponsiveContainer, ComposedChart,
  LineChart,
  Line,Area, Bar, 
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

export class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, stroke, value } = this.props;

    return (
      <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
        {value}
      </text>
    );
  }
}

export class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}
 

export class CustomizedLabelLineChart extends PureComponent {
  // static jsfiddleUrl = 'https://jsfiddle.net/alidingling/5br7g9d6/';

  render() {

    
    let service = {};
    let score = undefined;
    if (this.props.data) service = this.props.data.service;
    if (service.score !=undefined) score = this.props.data.service.score;

    if (!service.dptTrustScoreHistory) service.dptTrustScoreHistory = [
      {
        name: 'Month 1',
        company: 40.00,
        industry: 24.00,
        amt: 24,
      },
      {
        name: 'Month 2',
        company: 30.00,
        industry: 13.98,
        amt: 22.1,
      },
      {
        name: 'Month 3',
        company: 20.00,
        industry: 33.00,
        amt: 22.90,
      },
      {
        name: 'Month 4',
        company: 27.80,
        industry: 39.08,
        amt: 20.00,
      },
      {
        name: 'Month 5',
        company: 18.90,
        industry: 48.00,
        amt: 21.81,
      },
      {
        name: 'Month 6',
        company: 23.90,
        industry: 38.00,
        amt: 25.00,
      },
      {
        name: 'Month 7',
        company: score, // today
        industry: 33.00,
        amt: 20.00,
      },
    ];
 
    return (<div data-reactroot="" className="recharts-responsive-container mx-auto"  style={{width: '100%', height: '100%'}}  >
      {/* <div className="text-center d-flex justify-content-center mx-auto"
      // style={{width: '100%'}}
      > */}
        {/* <ResponsiveContainer> */}
      <LineChart
        width={960}
        height={300}
        data={service.dptTrustScoreHistory}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" height={60} tick={<CustomizedAxisTick />} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="company"
          stroke="#ff7f0e"
          // stroke="#8884d8"
          label={<CustomizedLabel />}
        />
        <Line type="monotone" dataKey="industry" stroke="#1f77b4" />
      </LineChart>
       {/* </ResponsiveContainer> */}
       {/* </div> */}
       </div>
    );
  }
}


var newArrModel = [];
var newArrValues = [];

 
export class ServiceTableList extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };
  render() {
    var service = {};
    var data = []

    if (this.props.data) service = this.props.data.service;
    var name = '';
    if (service) name = service.name;
    if (!service.dataBreaches) service.dataBreaches = [];

    
    if (this.props.items) data = this.props.items;
    // else data = service.dataBreaches;

    if (data == undefined || data == false)
      data = [];

    var prefix = this.props.prefix || '/news/';

    let documentViewLinks = false;
    if (this.props.documentViewLinks) documentViewLinks = this.props.documentViewLinks;

    return (
      <div>
        {/* <h4>History &amp; News</h4> */}
        {/* <p>
          A chronological history of incidents, news &amp; events pertaining to
          this service cyber health for {name}.
        </p> */}
        {/* <p>  //TODO - Add History timeline vis
        <Chartify 
            data={data} 
            container="films-container" 
            config={config} 
        /> </p> */}

        {/* <div className="container">
          {' '}
          <div className="row">
            {' '}
            <div className="col col-xs-12">
              {' '}
              <MicroBarChart style={{width: 'auto'}}
                width="500"
                height="50"
                // tooltip
                tipOffset={[0,20]}
                // tipTemplate={(d, i, data) => "value of #{d} at index #{i}, with #{data.length} data points"}
                hoverColor="#aaa"
                fillColor="#aaa" />
              &nbsp;
            </div>
          </div>{' '}
        </div> */}

        <div className="container mt-5b mb-5b">
          <div className="row">
            <div className="col-md-12 col-md-6b offset-md-3b">
              {/* <h4>Latest News</h4> */}
              {/* <ul className="timeline-feed"> */}
             
 <table 
          className="table table-hover table-responsive table-sm2
           
           "
        >
        {/* <table className="table table-hover table-responsive table-sm2 "> */}
          {/* <thead className="22">  */}

          <thead className="">
            <tr>
              {/* <th /> */}
              <th />
              <th className="">Content</th>
              {/* <th className="text-center">Date</th> */}
              <th className="">Category</th>
              {/* <th className=" d-none d-sm-table-cell"><span className=" ">Description</span></th> */}
              {/* <th className=" d-none">Importance to me</th> */}
            </tr>
          </thead>
          
          <tbody>
{ data.length>0 ? (data.map(function(item, key) {



return <TimelineItemRowTableRow service={service} item={item} prefix={prefix} documentViewLinks={ documentViewLinks} />;
})

) : <></>}
                
             </tbody>
             </table>
                {/*               
                <li>
                  <a target="_blank" href="https://www.totoprayogo.com/#">
                    New Web Design
                  </a>
                  <a href="#" className="float-right">
                    21 March, 2014
                  </a>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Quisque scelerisque diam non nisi semper, et elementum lorem
                    ornare. Maecenas placerat facilisis mollis. Duis sagittis
                    ligula in sodales vehicula....
                  </p>
                </li>
                <li>
                  <a href="#">21 000 Job Seekers</a>
                  <a href="#" className="float-right">
                    4 March, 2014
                  </a>
                  <p>
                    Curabitur purus sem, malesuada eu luctus eget, suscipit sed
                    turpis. Nam pellentesque felis vitae justo accumsan, sed
                    semper nisi sollicitudin...
                  </p>
                </li>
                <li>
                  <a href="#">Awesome Employers</a>
                  <a href="#" className="float-right">
                    1 April, 2014
                  </a>
                  <p>
                    Fusce ullamcorper ligula sit amet quam accumsan aliquet. Sed
                    nulla odio, tincidunt vitae nunc vitae, mollis pharetra
                    velit. Sed nec tempor nibh...
                  </p>
                </li> */}
              {/* </ul> */}
            </div>
          </div>
        </div>
    {/* <hr />
        <Timeline lineColor={'#ddd'}>
          {service.dataBreaches.map(function(item, key) {
            return <TimelineItemRow item={item} />;
          })}
        </Timeline>  */}
      </div>
    );
  }
}



const More =   ({ item, description }) => (
  <span className="d-inline-block">
    <a
      href="#"
      className="btn-sm btn-default collapsed"
      data-toggle="collapse"
      data-target={`#`+item.key}
      aria-expanded="false"
    >
      <i className="fas fa-info-circle" />
      <span className="glyphicon glyphicon-question-sign" />
    </a>

    <div id={item.key} className="text-small report-info collapse clear"> 
    
    
    <div dangerouslySetInnerHTML={{__html: description.replace(/(<? *script)/gi, 'illegalscript')}} >
                </div> 

    <div dangerouslySetInnerHTML={{__html: item.more.replace(/(<? *script)/gi, 'illegalscript')}} >
                </div> 

    <div dangerouslySetInnerHTML={{__html: item.info.replace(/(<? *script)/gi, 'illegalscript')}} >
                </div> 
    </div>
  </span>
);


export function formatDate(date) {

  if (date == "" || date == null) return null
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

 

export function formatDate2(inputdate) {
  var date = new Date(inputdate);
var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
return dateString;
} 