import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';  
 

// import swal from 'sweetalert'; 

import { Link } from 'gatsby';

import TimeAgo from 'react-timeago'

import $ from 'jquery';

import logo from '../../pages/DPT_1_Primary_logo_on_transparent_512.png';

// const ServicePrivacyPolicySummary = props => {
    
import ServiceTagData, {formatDate, ServiceTableList, ServiceDescriptionData, ServiceTabularData, JsonToTableApp, StatusIndicatorBoolean, StatusIndicator, DataGridApp,StatusIndicatorGaugePrimary,  StatusScorePrimary,  Status, StatusScore, StatusIndicatorGauge } from './serviceData';

// import 'flag-icon-css'

var QRCode = require('qrcode.react');
let rootUrl = process.env.REACT_APP_appWebsitePath

if (process.env.NODE_ENV=="production")
  if ((process.env.GATSBY_ACTIVE_ENV=="demo" || process.env.REACT_APP_BUILD_ENV=="demo" ))
    rootUrl = "https://app.datapolicytrust.com/"

import '../service.css';

class ServicePrivacyPolicySummary extends React.Component {
    
  constructor(props) {
    super(props);
    // this.myRef = React.createRef()   // Create a ref object
    this.state = { 
      boolHeaderLoaded: false, 
    };
  }
  static propTypes = {
    // data: PropTypes.object.isRequired
  };

    // static propTypes = {
    //   data: PropTypes.object.isRequired,
    // };

    


  // function showData() {
  // 	var outputHTML = "";
    
  // 	// outputHTML += getNoticeTitleBlock();
  // 	// outputHTML += drawElements();
    
  // 	// $("#privacy_label").html(outputHTML);
  // 	$("#privacy_label").show();
    
  // 	//draw the details invisibly after showing the visible stuff
  // 	$("#dataDetailLoad").html(drawDetails());
    
  // }


  //  let boolHeaderLoaded = false;
  loadDetailHeader(strDetailTitle) {

    // if (strDetailTitle) {
    //   $("#DPT_PL_detail_title").html(strDetailTitle);
    // }
      
    // if (!this.boolHeaderLoaded)	{

    //   $("#DPT_PL_detail_title").show();
    //     this.boolHeaderLoaded = true;
      
    //   //handle the back button
    //   // window.history.pushState(null,null,'#');
      
    //     $("#detail_container").show();
      
    // }

  }
  
  onShow(strIDToShow, strPassedDetailTitle) {
    //TODO
    //hide all
    // $('.collapse').not(strIDToShow).collapse('hide');
    // $('.factor_detail').hide();

    //console.log($(strIDToShow).is(':visible'));
    // if (!$(strIDToShow).is(':visible')) {
    // 	$(strIDToShow).collapse('show');		
    // }
    
    //load a header from AJAX
    this.loadDetailHeader(strPassedDetailTitle);
    //scroll to the top to view the detail page
    // window.scrollTo(0,0);
  }


  render() {

    // require("../servicePrivacyLabel.js")
    var service = {};

    var fullMode = false;
  
      if (this.props.data) service = this.props.data.service;

      if (this.props.full) fullMode = (this.props.full=="true"? true : false);
  

      let updatedAt = null
      // console.log("updatedAt")
      try {
        // console.log(this.props.service_data.service_data.date)
        updatedAt = this.props.service_data.service_data.date;
        // alert(updatedAt);
        
      } catch (error) {
        // console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      let  countryHQ = null
      // console.log("updatedAt")
      try {
        // console.log(this.props.service_data.service_data.date)
        countryHQ =service.countryHQ.toLowerCase()
        // alert(updatedAt);
        
      } catch (error) {
        // console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      let item = {};
      item.score = service.score;  
    return (
      <div>  
        <div className="row">      
          <div className={fullMode? "col-xs-4":"col-lg-12"}>
            <div id="label_container">
              <div id="privacy_label" >
                <div id="DPT_PL_title" className="label_title">
                  Digital Trust Facts
                </div>
                {/* <div id="DPT_PL_intro">
                  Click <span style={{"color":"#007bff"}}>links</span> for more
                  detail.
                </div> */}
                           {/* {service.score} */}
                <div className="label_box_top">
                 {/*  <div className=" label_row pb-3">
                    <span className="label_entry">
                     
                    <Link
                            to={rootUrl+`service/${service.id}/scorecard/`}
                            title={`View Trust Score reports on ${
                              service.name
                            }`}
                          >  

<StatusIndicatorGaugePrimary item={item} /> <StatusScorePrimary item={item} />
                          </Link> 
                    </span>
                    <span className="label_prompt">
                     Trust Score
                    </span>
                  </div> */}
                  <div className=" label_box" />
                  <div className=" label_row">
                    <span className="label_entry">

                    <Link
                            to={rootUrl+`service/${service.id}/`}
                            title={`Learn more about ${
                              service.name
                            }`}
                          >   {service.name}
                          </Link> 
 
                    </span>
                    <span className="label_prompt">
                      Company name
                    </span>
                  </div>
                  <div className=" label_row">
                    <span className="label_entry">
                      {/* <a onClick={this.onShow('#DPT_PL2_CID','Our Website and Domain')}
                      href={service.domain || service.id}
                        // href="javascript:onShow('#DPT_PL2_CID','Our company');"                        
                      >
                        {service.domain || service.id}
                      </a> */}
                      <Link
                            to={rootUrl+`service/${service.id}/`}
                            title={`Learn more about ${
                              service.name
                            }`}
                          >   {service.name}
                          </Link> 
                    </span>
                    <span className="label_prompt">
                      Domain
                    </span>
                  </div>

                  <div className=" label_row_no_bottom_line d-none">
                    <span className="label_entry">
                      
                      
                    <Link
                            to={rootUrl+`service/${service.id}/organization/`}
                            title={`View See Current Overview of the Organization behind ${
                              service.name
                            }`}
                          > 
                              <span className="display-block-inline-none text-muted2 text-small2 badge-none    badge-default-none text-default2">
                              {/* Learn more&nbsp; */}
                              <i className="fas fa-info-circle"></i>
                              </span> 
                          </Link> 


                    </span>
                    <span className="label_prompt">
                      Who We Are
                    </span>
                  </div>
                  <div className=" label_box" />
                  <div className=" label_row_no_bottom_line">
                    <span className="label_entry">
                    <Link
                            to={rootUrl+`service/${service.id}/policies/data-collection/`}
                            title={`View Data Collection policies of ${
                              service.name
                            }`}
                          > 
                              <span className="display-block-inline text-muted2 text-small2 badge-none    badge-default-none text-default2">
                              {/* Learn more&nbsp; */}
                              <i className="fas fa-info-circle"></i>
                              </span> 
                          </Link> 

                      {/* <a
                        onClick={this.onShow('#DPT_PL2_TYP','What we collect')}
                        href="#"
                        // href="javascript:onShow('#DPT_PL2_TYP','What we collect');"
                        
                      >
                        Email, Location
                      </a> */}
                    </span>
                    <span className="label_prompt">
                      What we collect
                    </span>

                  </div>
                  <div className="label_row_no_bottom_line ">


                  <span className="data-collection-icons d-none2">
                    <i className="fa fa-envelope fa-1x text-blue" aria-hidden="true" title="Email Address"></i>
                    <i className="fa fa-user fa-1x text-blue" aria-hidden="true" title="Name"></i>
                    <i className="fa fa-phone fa-1x  text-blue" aria-hidden="true" title="Mobile Phone Number"></i> 
                    <i className="fa fa-id-card fa-1x text-blue" aria-hidden="true" title="Government ID"></i>
                    <i className="fa fa-users fa-1x text-blue" aria-hidden="true" title="Contacts"></i>
                    <i className="fa fa-user-secret fa-1x text-blue" aria-hidden="true" title="Private Data"></i>
                    <i className="fa fa-wifi fa-1x text-blue" aria-hidden="true" title="Wifi"></i>
                    <i className="fab fa-bluetooth fa-1x text-blue" aria-hidden="true" title="Bluetooth"></i>
                    <i className="fa fa-microphone fa-1x text-blue" aria-hidden="true" title="Microphone"></i>
                    <i className="fa fa-camera fa-1x text-blue" aria-hidden="true" title="Photos"></i>
                    <i className="fab fa-video-camera fa-1x text-blue" aria-hidden="true" title="Video"></i>
                    <i className="fab fa-line-chart fa-1x text-blue" aria-hidden="true" title="Usage Analytics"></i>
                    <i className="fa fa-keyboard fa-1x text-blue" aria-hidden="true" title="Keyboard Logging"></i>
                    <i className="fa fa-mouse fa-1x text-blue" aria-hidden="true" title="Mouse Tracking"></i>
                    <i className="fa fa-credit-card fa-1x  text-blue" aria-hidden="true" title="Credit Card"></i> 
                    <i className="fab fa-money fa-1x  text-blue" aria-hidden="true" title="Financial or Payment Information"></i> 
                    <i className="fa fa-medkit fa-1x  text-blue" aria-hidden="true" title="Health Data"></i> 
                    <i className="fa fa-location-arrow fa-1x  text-blue" aria-hidden="true" title="Geolocation Data"></i> 
                    
                    <i className="fa fa-search fa-1x  text-blue" aria-hidden="true" title="Search History"></i> 
                    <i className="fa fa-file fa-1x  text-blue" aria-hidden="true" title="Documents"></i> 
                    <i className="fa fa-comments fa-1x  text-blue" aria-hidden="true" title="Text Messages"></i> 
                    {/* <i className="fa fa-sign-in fa-1x text-blue " aria-hidden="true" title="Social"></i> */}
                    </span>

                  </div>
                     <div className=" label_row">
                    <span className="label_entry">



<span className={"flag-icon flag-icon-"+countryHQ}></span>
{/* <span className="flag-icon flag-icon-gr flag-icon-squared"></span> */}

                    <Link
                           to={rootUrl+`service/${service.id}/organization/`}
                           
                          //  {`/countries/${
                          //   service.countryHQ
                          // }`}
                            title={`View Data Storage location policies of ${
                              service.name
                            }`}
                          > 
                              <span className="display-block-inline text-muted2 text-small2 badge-none    badge-default-none text-default2">
                                {/* In the{' '} */}
                                 {/* {service.locationHQ} */}
                                 {service.countryHQ}   &nbsp;<i className="fas fa-info-circle"></i>
                              </span> 
                          </Link> 



                          {/* <Link
                                                          className="btn btn-xs btn-soft-secondary"
                                                            to={`/countries/${
                                                              service.countryHQ
                                                            }`}
                                                            title={
                                                              service.countryHQ
                                                            }
                                                          >
                                                            
                                                            {service.countryHQ} 
                                                          </Link> */}

                      {/* <a
                        onClick={this.onShow('#DPT_PL2_XFR','Where we store your data')}
                        href="#"
                        // href="javascript:onShow('#DPT_PL2_XFR','Where we store your data');"
                        
                      >
                        in the EU
                      </a> */}
                    </span>
                    <span className="label_prompt">
                      Where we store your data
                    </span>
                  </div>
                  <div className=" label_row">
                    <span className="label_entry">
                    <Link
                            to={rootUrl+`service/${service.id}/policies/data-utilization/`}
                            title={`View Data Use policies of ${
                              service.name
                            }`}
                          > 
                              <span className="display-block-inline text-muted2 text-small2 badge-none    badge-default-none text-default2">
                                {/* Data Use &nbsp; */}
                                <i className="fas fa-info-circle"></i>
                              </span> 
                          </Link> 
{/* 
                      <a
                        onClick={this.onShow('#DPT_PL2_PPS','How we use your data')}
                        href={rootUrl+'/service/' + service.id + '/risk/'}
                        // href="javascript:onShow('#DPT_PL2_PPS','How we use your data');"                        
                      >
                        List of purposes
                      </a> */}
                    </span>
                    <span className="label_prompt">
                      How we use your data
                    </span>
                  </div>
                  {/* <div className=" label_row  d-none">
                    <span className="label_entry">
                      <a
                        onClick={this.onShow('#DPT_PL2_BSS','Legal basis for using your data')}
                        href="#"
                        // href="javascript:onShow('#DPT_PL2_BSS','Legal basis for using your data');"
                        
                      >
                        Legitimate Interests
                      </a>
                    </span>
                    <span className="label_prompt">
                      Legal use of your data
                    </span>
                  </div> */}
                  {/* <div className=" label_row d-none">
                    <span className="label_entry"> 
                    <Link
                              to={rootUrl+`service/${service.id}/policies/data-rights/`}
                              title={`View Data Rights policies of ${
                                service.name
                              }`}
                            > 
                                <span className="display-block-inline text-muted2 text-small2 badge-none    badge-default-none text-default2">
                                  Data Rights 
                                </span> 
                            </Link> 

                      
                        <a
                          onClick={this.onShow('#DPT_PL2_RTS','Your privacy rights')}
                          href="#"
                          // href="javascript:onShow('#DPT_PL2_RTS','Your privacy rights');"
                          
                        >
                          Detailed explanation
                        </a>
                     </span>
                    <span className="label_prompt">
                      Your rights
                    </span>
                  </div> */} 
                  <div className=" label_row">
                    <span className="label_entry">
                      {/* up to 13 months */}
                      <Link
                            to={rootUrl+`service/${service.id}/policies/data-retention/`}
                            title={`View Data Retention policies of ${
                              service.name
                            }`}
                          > 
                              <span className="display-block-inline text-muted2 text-small2 badge-none    badge-default-none text-default2">
                                {/* Data Retention  &nbsp; */}
                                <i className="fas fa-info-circle"></i>
                                 {/* (TODO : Up to 13 months) */}
                              </span> 
                          </Link> 

                    </span>
                    <span className="label_prompt">
                      {/* We retain your data for */}
                      How long we retain your data
                    </span>
                  </div>
               
                  <div className=" label_row">
                    <span className="label_entry">

                    <Link
                            to={rootUrl+`service/${service.id}/policies/data-processors/`}
                            title={`View Data Sharing policies of ${
                              service.name
                            }`}
                          > 
                              <span className="display-block-inline text-muted2 text-small2 badge-none    badge-default-none text-default2">
                                {/* Third Parties &nbsp; */}
                                <i className="fas fa-info-circle"></i>
                              </span> 
                          </Link> 

                      {/* <a
                        onClick={this.onShow('#DPT_PL2_SHR','Who we share your data with')}
                        href="#"
                        // href="javascript:onShow('#DPT_PL2_SHR','Who we share your data with');"
                        
                      >
                        Third parties
                      </a> */}
                    </span>
                    <span className="label_prompt">
                      How we share your data
                    </span>
                  </div>
                  <div className=" label_row">
                    <span className="label_entry">
                      <a
                        onClick={this.onShow('#DPT_PL2_CKE','We use \'cookies\'')}
                        href="#"
                        // href="javascript:onShow('#DPT_PL2_CKE','We use \'cookies\'');"
                        
                      >
                        {/* Certain purposes &nbsp; */}
                        <i className="fas fa-info-circle"></i>
                      </a>
                    </span>
                    <span className="label_prompt">
                      We use cookies
                    </span>
                  </div>
                  <div className=" label_box" />
                  <div className=" label_row">
                    <span className="label_entry">
                      <Link
                        to="#"
                        target="_detail"
                        
                      >
                        Privacy policy
                      </Link>
                    </span>
                    <span className="label_prompt">
                      Detailed privacy info
                    </span>
                  </div>
                  {/* <div className=" label_row d-none">
                    <span className="label_entry">
                      <Link
                        to="#"
                        target="_detail"
                        
                      >
                        Privacy Dialog
                      </Link>
                    </span>
                    <span className="label_prompt">
                      Alternate notice
                    </span>
                  </div>   */}
                  <div className=" label_row">
                    <span className="label_entry">
                      <Link
                        to={rootUrl+`service/${service.id}/policies/`}
                        // target="_detail"
                        
                      >
                        {/* Learn more &nbsp; */}
                        <i className="fas fa-info-circle"></i>
                      </Link>
                    </span>
                    <span className="label_prompt">
                      Other Policies &amp; Terms
                    </span>
                  </div>
                  {/* <div className=" label_box" /> */}
                  <div className=" label_row">
                    <span className="label_entry">
                      {/* <a
                        onClick={this.onShow('#DPT_PL2_DPO','Privacy Contact')}
                        href="/"
                        // href="javascript:onShow('#DPT_PL2_DPO','Privacy Contact');"
                        
                      >
                        our DPO
                      </a> */}

                      
                    <Link
                            to={rootUrl+`service/${service.id}/contacts/`}
                            title={`View Data Privacy Officer contact info of ${
                              service.name
                            }`}
                          > 
                              <span className="display-block-inline text-muted2 text-small2 badge-none    badge-default-none text-default2">
                                our DPO
                              </span> 
                          </Link> 

                    </span>
                    <span className="label_prompt">
                      Privacy Contact
                    </span>
                  </div>

                  <div className=" label_box" />
                  <div className=" label_row">
                    <span className="label_entry">
                     {/* 2019.01.03 
                       */}
                       {/* TODO - data bind dates*/}
                       {' '}
                    </span>
                    <span className="label_prompt">
                      Effective Date
                    </span>
                  </div>
                  
                  <div className=" label_row">
                    <span className="label_entry">
                      {/* 2019.01.03
                       */}
                       {/* TODO - data bind dates*/}
                       {' '}
                       
                    <Link
                            to={rootUrl+`service/${service.id}/activity/`}
                            title={`View Activity feed of ${
                              service.name
                            }`}
                          > 
                              <span className="display-block-inline text-muted2 text-small2 badge-none    badge-default-none text-default2">
                                
                                <TimeAgo date={updatedAt}/> - {formatDate(updatedAt)}

                              </span> 
                          </Link> 
                    </span>
                    <span className="label_prompt">
                      Last Updated
                    </span>
                  </div>
              
                  <div className=" label_row">
                    <span className="label_entry">
                      
                      
                    <Link
                            to={rootUrl+`service/${service.id}/risks/track-record/`}
                            title={`View See Current Events of ${
                              service.name
                            }`}
                          > 
                              <span className="display-block-inline-none text-muted2 text-small2 badge-none    badge-default-none text-default2">
                              See Current Events &nbsp;  
                                <i className="fas fa-info-circle"></i>
                              </span> 
                          </Link> 


                    </span>
                    <span className="label_prompt">
                      Our Track Record
                    </span>
                  </div>
                  {/* <div className=" label_row">
                    <span className="label_entry">
                      <a
                        href="#"
                        target="_detail"
                        
                      >
                        0:30 Video
                      </a>
                    </span>
                    <span className="label_prompt">
                      Video explainer
                    </span>
                  </div> */}
                  {fullMode? <></>:     <div className=" label_row">
                    <span className="label_entry">
                      <Link
                        to={rootUrl+"service/"+service.id+"/privacy/"}
                        target="_detail"
                        className="ajLink text-info"
                      >
                          <span className=" badge badge  badge-primary">Learn More</span>
                        
                      </Link>
                    </span>
                    <span className="label_prompt">
                      More Information
                    </span>
                  </div>
            }
            
                    <div className=" label_row_no_bottom_line">
                     <span className="label_entry_qrcode">
                      <small><b>
                      QR Code </b>
  &nbsp;<i className="fas fa-info-circle"></i>  
                      </small>
                      <span className="label_prompt">
                      <Link
                          to={rootUrl+"service/"+service.id+"/"}
                    
                          className="  text-info"
                        >
                          <QRCode
                            value={`https://datapolicytrust.com/service/${service.domain}/`}
                            size={128}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"M"}
                            includeMargin={true}
                            renderAs={"svg"}
                            // imageSettings={{
                            //   src: "https://www.datapolicytrust.com/static/dptlogo-0ae0969b71012bf5c19975475ec69e56.png",
                            //   x: null,
                            //   y: null,
                            //   height: 18,
                            //   width: 100,
                            //   excavate: true,
                            // }}
                          />  <img width="100" className="pt-3" src={logo}/> <br/>
                      </Link> 
                    </span>

                    
                      </span>  
                      {/* <span className="label_prompt">
                      
                      </span> */}


                      <small>

                        Please be sure to review and understand the legal agreements and policies of this organization before using their service.
                      </small>
                    </div>
                  </div>
              </div>
              {/* <!--#facts--> */}
            </div>
            <br />
            <div id="buttons_container" />
          </div>
          <div className={fullMode? "col-md-8":"d-none"}>
            <div id= {fullMode? "detail_container":"d-none"}>
              <div id="detail">
                <div id="DPT_PL_detail_title" className="label_title">
                  Detailed Privacy Facts
                </div>
                <div id="dataDetailLoad">
                  <div id="DPT_PL2_BSS" className="collapse">
                    <div className="detail_head">
                      Our legal basis for processing your data is that we have
                      assessed that it is in our legitimate interests to process
                      your personal data.
                    </div>
                    <hr />
                    <div>
                      <ul>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_BSS',0,1);">
                            Basis of Legitimate Interests
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_BSS0"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          The legal basis for processing personal data is based
                          on the assessment that it is in our legitimate
                          interests to process personal data as enumerated in
                          point (f) of Article 6(1) of the GDPR.
                        </div>
                      </ul>
                    </div>
                    <div className="detail_foot" />
                    <br />
                    <br />
                  </div>
                  <div id="DPT_PL2_CID" className="collapse">
                    <div className="detail_head">
                      COMPANY SUMMARY
                    </div>
                    <hr />
                    <div>
                      <ul>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_CID',0,4);">
                            Location of our world headquarters
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_CID0"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          OUR ADDRESS
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_CID',1,4);">
                            Our Customer Service Hotline telephone number within
                            the European Union (EU)
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_CID1"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          EU +99 1111 11111
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_CID',2,4);">
                            Our Customer Service Hotline telephone number within
                            the United States (USA)
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_CID2"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          USA +1 111-654-3210
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_CID',3,4);">
                            Email address of our corporate Data Protection
                            Officer (DPO)
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_CID3"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          ourdpo@{service.domain}
                        </div>
                      </ul>
                    </div>
                    <hr />
                    <div className="detail_foot">
                      We value your privacy and welcome this opportunity to give
                      you full transparency into how we use the personal
                      information you might consent to share with us as we do
                      business together.
                    </div>
                    <a
                      href="#"
                      target="_new"
                      className="detail_link"
                    >
                      Additional general information about us can be found on
                      our corporate website.
                    </a>
                    <br />
                    <br />
                  </div>
                  <div id="DPT_PL2_CKE" className="collapse">
                    <div className="detail_head">
                      We use the following cookies for a variety of purposes
                    </div>
                    <hr />
                    <div>
                      <ul>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_CKE',0,5);">
                            Operational Cookies
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_CKE0"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          These cookies are essential in order to enable you to
                          move around the Site and use its features, such as
                          accessing secure areas of the Site. Without these
                          cookies, services like enabling appropriate content
                          based on your type of device cannot be provided.
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_CKE',1,5);">
                            Performance Cookies
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_CKE1"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          These cookies collect information about how visitors
                          use the Site, so that we can analyse traffic and
                          understand how our visitors use our Sites. We may use
                          third party service providers for this purpose who may
                          use their own cookies. These cookies don’t collect
                          information that identify an individual visitor. All
                          information these cookies collect is aggregated and
                          therefore anonymous. It is only used to improve how
                          the Site works.
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_CKE',2,5);">
                            Functionality Cookies
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_CKE2"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          These cookies allow our Sites to remember choices you
                          make (such as your user name, or the region you are
                          in) and provide enhanced, more personal features.
                          These cookies can also be used to remember changes you
                          have made to text size, fonts and other parts of web
                          pages that you can customise. They may also be used to
                          provide services you have asked for such as watching a
                          video or commenting on a blog. The information these
                          cookies collect may be anonymised and they cannot
                          track your browsing activity on other websites.
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_CKE',3,5);">
                            Social Media Cookies
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_CKE3"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          These cookies are used when you share information
                          using a social media sharing button or “like” button
                          on our Sites or you link your account or engage with
                          our content on or through a social media site such as
                          Facebook, Twitter or Google+. The social network will
                          record that you have done this. This information may
                          be linked to targeting/advertising activities.
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_CKE',4,5);">
                            Advertising Cookies
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_CKE4"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          Some of our Sites may use a third party advertising
                          network to deliver targeted advertising. These may
                          also have the capability to track your browsing across
                          different sites and apps. If you would like more
                          information on targeted advertising and how to opt out
                          of it, please visit www.youronlinechoices.com.
                        </div>
                      </ul>
                    </div>
                    <hr />
                    <div className="detail_foot">
                      If you don't want to receive cookies, you can also alter
                      your browser settings or your mobile device settings. The
                      procedure for doing so varies from one browser application
                      to another. By disabling cookies, you may be prevented
                      from accessing some features of our Sites or certain
                      content or functionality may not be available.
                    </div>
                    <br />
                    <br />
                  </div>
                  <div id="DPT_PL2_DBD" className="collapse">
                    <div className="detail_head">
                      The {service.id} User Privacy Dashboard allows you to manage
                      your privacy choices
                    </div>
                    <hr />
                    <div>
                      <ul>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_DBD',0,3);">
                            Exercise your rights
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_DBD0"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          Use the {service.id} User Privacy Dashboard to exercise
                          your rights such as the right to access or the right
                          to be forgotten
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_DBD',1,3);">
                            Modify your privacy choices
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_DBD1"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          Change your privacy choices online through the {service.id} User Privacy Dashboard
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_DBD',2,3);">
                            View privacy documentation
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_DBD2"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          View the very privacy notice you were offered when you
                          made your privacy choices using the {service.name} User
                          Privacy Dashboard
                        </div>
                      </ul>
                    </div>
                    <hr />
                    <div className="detail_foot" />
                    <a
                      href="#"
                      target="_new"
                      className="detail_link"
                    >
                      Access the {service.id} User Privacy Dashboard
                    </a>
                    <br />
                    <br />
                  </div>
                  <div id="DPT_PL2_DCD" className="collapse">
                    <div className="detail_head">
                      Contact {service.id} via these methods:
                    </div>
                    <hr />
                    <div>
                      <ul>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_DCD',0,3);">
                            Global Headquarters
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_DCD0"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          OUR ADDRESS
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_DCD',1,3);">
                            Customer Service Hotline (US)
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_DCD1"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          US (800) 111-1111
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_DCD',2,3);">
                            Customer Service Hotline (EU)
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_DCD2"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          1 +__
                        </div>
                      </ul>
                    </div>
                    <div className="detail_foot" />
                    <br />
                    <br />
                  </div>
                  <div id="DPT_PL2_DPA" className="collapse">
                    <div className="detail_head">
                      The European Union has 28 data protection authorities. In
                      addition, the union itself has an enforcement agency.
                    </div>
                    <hr />
                    <div>
                      <ul>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_DPA',0,3);">
                            By email:
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_DPA0"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          dpa12345@gdpregulator.org
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_DPA',1,3);">
                            By conventional mail:
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_DPA1"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          DPA Name, DPA Address
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_DPA',2,3);">
                            By telephone:
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_DPA2"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          US +___
                        </div>
                      </ul>
                    </div>
                    <hr />
                    <div className="detail_foot" />
                    <a
                      href="https://ec.europa.eu/info/law/law-topic/data-protection/reform/what-are-data-protection-authorities-dpas_en"
                      target="_new"
                      className="detail_link"
                    >
                      Click here for more information about the Data Protection
                      Authority{' '}
                    </a>
                    <br />
                    <br />
                  </div>
                  <div id="DPT_PL2_DPO" className="collapse">
                    <div className="detail_head">
                      You can contact our Data Protection Officer as follows:
                    </div>
                    <hr />
                    <div>
                      <ul>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_DPO',0,3);">
                            Via email:
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_DPO0"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          DPO@{service.domain} 
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_DPO',1,3);">
                            Using conventional mail:
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_DPO1"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          ADDRESS
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_DPO',2,3);">
                            Via telephone:
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_DPO2"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          US +_
                        </div>
                      </ul>
                    </div>
                    <hr />
                    <div className="detail_foot" />
                    <a
                      href="https://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612048"
                      target="_new"
                      className="detail_link"
                    >
                      Click here to learn about a DPO's resonsibilities under
                      the GDPR.
                    </a>
                    <br />
                    <br />
                  </div>
                  <div id="DPT_PL2_FPP" className="collapse">
                    <div className="detail_head">
                      {service.name}'s fulll legal privacy policy is available
                      online.
                    </div>
                    <hr />
                    <div className="detail_foot" />
                    <a
                      href="#"
                      target="_new"
                      className="detail_link"
                    >
                      Click here to view our full privacy policy.
                    </a>
                    <br />
                    <br />
                  </div>
                  <div id="DPT_PL2_NOT" className="collapse">
                    <div className="detail_head">
                      A less brief version of this notice is available online
                    </div>
                    <hr />
                    <div className="detail_foot" />
                    <a
                      href="#"
                      target="_new"
                      className="detail_link"
                    >
                      Our privacy notice
                    </a>
                    <br />
                    <br />
                  </div>
                  <div id="DPT_PL2_PPS" className="collapse">
                    <div className="detail_head">
                      We use your private information we collect from you to
                      communicate with you as we deliver our corporate services
                      to you.
                    </div>
                    <hr />
                    <div>
                      <ul>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_PPS',0,4);">
                            To customize your experience
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_PPS0"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          We store any choices you make to customize your
                          experience with {service.name} websites and mobile apps.
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_PPS',1,4);">
                            To communicate with you
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_PPS1"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          We store your private information to communicate with
                          you in the future.
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_PPS',2,4);">
                            To offer you benefits
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_PPS2"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          {service.name} will offer benefits that we believe you
                          will want based on how you've used our services in the
                          past.
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_PPS',3,4);">
                            To offer you discounts
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_PPS3"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          {service.name} will offer discounts on services that we
                          believe you will want based on what you've purchased
                          in the past.
                        </div>
                      </ul>
                    </div>
                    <div className="detail_foot" />
                    <br />
                    <br />
                  </div>
                  <div id="DPT_PL2_RES" className="collapse">
                    <div className="detail_head">
                      There are plenty of applicable resources. Here are some:
                    </div>
                    <hr />
                    <div>
                      <ul>
                        <li className="factor_text">
                          <a href="https://ec.europa.eu/info/law/law-topic/data-protection_en">
                            Rules for the protection of personal data inside and
                            outside the EU
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_RES0"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          https://ec.europa.eu/info/law/law-topic/data-protection_en
                        </div>
                      </ul>
                    </div>
                    <hr />
                    <div className="detail_foot" />
                    <a
                      href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex:32016R0679"
                      target="_new"
                      className="detail_link"
                    >
                      Click here to view the full text of the GDPR.
                    </a>
                    <br />
                    <br />
                  </div>
                  <div id="DPT_PL2_RTS" className="collapse">
                    <div className="detail_head">
                      As a "data subject" under the GDPR, you are granted many
                      rights to control how your private data is used and
                      protected. Here are summaries of some of the rights we
                      believe might be most important to you:
                    </div>
                    <hr />
                    <div>
                      <ul>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_RTS',0,6);">
                            You have the right to access information we hold
                            about you.
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_RTS0"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          This includes the right to ask us about the categories
                          of data were processing, the purposes of data
                          processing, the categories of third parties to whom
                          the data maybe disclosed, how long the data will be
                          stored (or the criteria used to determine that
                          period), and your other rights regarding our use of
                          your data. We will provide you with the information
                          within one month of your request, unless doing so
                          would adversely affect the rights and freedoms of
                          others.{' '}
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_RTS',1,6);">
                            You have the right to make us correct any inaccurate
                            personal data about you
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_RTS1"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          If you discover that we have details about you that
                          are not factually correct, you can request us to
                          change or remove these details.
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_RTS',2,6);">
                            You can object to us using your data for profiling
                            you or making automated decisions about you
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_RTS2"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          We may use your data to determine whether we should
                          let you know information that might be relevant to you
                          (for example, tailoring emails to you based on your
                          requests). Otherwise, the only circumstances in which
                          we will do this is to provide the service to you.
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_RTS',3,6);">
                            You have the right to port your data to another
                            service.
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_RTS3"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          We will give you a copy of your data in CSV or JSON
                          format so that you can provide it to another service.
                          If you ask us and it is technically possible, we will
                          directly transfer the data to the other service for
                          you. We will not do so to the extent that this
                          involves disclosing data about any other individual.
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_RTS',4,6);">
                            You have the right to be forgotten by us.
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_RTS4"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          You can do this by asking us to erase any personal
                          data we hold about you.{' '}
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_RTS',5,6);">
                            You have the right to lodge a complaint regarding
                            our use of your data
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_RTS5"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          Please tell us first, so we have a chance to address
                          your concerns. If we fail in this, you can address any
                          complaint to the UK information Commissioner's Office,
                          either by calling their helpline or as directed on
                          their website at www.ico.org.uk.
                        </div>
                      </ul>
                    </div>
                    <hr />
                    <div className="detail_foot">
                      We value your privacy and welcome this opportunity to give
                      you full transparency into how we use the personal
                      information you might consent to share with us as we do
                      business together.
                    </div>
                    <br />
                    <br />
                  </div>
                  <div id="DPT_PL2_SHR" className="collapse">
                    <div className="detail_head">
                      {service.name} shares data with trusted partners who may
                      contact you about their services. If you do not want to
                      have your data shared in this way, you can revoke your
                      consent for sharing on the DPT Privacy Dashboard.
                    </div>
                    <hr />
                    <div>
                      <ul>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_SHR',0,4);">
                            Government Agencies
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_SHR0"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          Only in an emergency
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_SHR',1,4);">
                            Marketing Partners
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_SHR1"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          We may partner with other companies to provide you
                          with products, services, or offers based upon your
                          experiences with us, and may share your information
                          with our business partners accordingly.
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_SHR',2,4);">
                            Contractors
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_SHR2"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          We may share personal information within the Resort
                          Inn portfolio of brands.
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_SHR',3,4);">
                            User Community
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_SHR3"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          If you visit {service.name} as part of a group event or
                          meeting, information collected for meeting and event
                          planning may be shared with the organizers of those
                          meetings and events, and, where appropriate, guests
                          who organize or participate in the meeting or event.
                        </div>
                      </ul>
                    </div>
                    <div className="detail_foot" />
                    <br />
                    <br />
                  </div>
                  <div id="DPT_PL2_STO" className="collapse">
                    <div className="detail_head">
                      If you do not interact with us for 13 months, the private
                      data we have collected from you is deleted.{' '}
                    </div>
                    <hr />
                    <div className="detail_foot" />
                    <a
                      href="#"
                      target="_new"
                      className="detail_link"
                    >
                      Click here to log into a {service.name} Loyalty Account.
                    </a>
                    <br />
                    <br />
                  </div>
                  <div id="DPT_PL2_TYP" className="collapse">
                    <div className="detail_head">
                      {service.name} may collect the following categories of data
                      for the following purposes.
                    </div>
                    <hr />
                    <div>
                      <ul>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_TYP',0,3);">
                            Your contact details
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_TYP0"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          With your consent, personal data we collect from you
                          on our website is used to contact you to share
                          information about our products and services. This data
                          is kept only as long as it is needed.
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_TYP',1,3);">
                            Limited financial information
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_TYP1"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          We only collect your financial information to execute
                          a transaction. Your financial data is not stored once
                          the transaction is complete.{' '}
                        </div>
                        <li className="factor_text">
                          <a href="javascript:showMoreFactorData('DPT_PL2_TYP',2,3);">
                            Data on how you use our website
                          </a>
                        </li>
                        <div
                          id="DPT_PL2_TYP2"
                          style={{"display":"none"}}
                          className="factor_detail"
                        >
                          We collect anonymized data about how you use our
                          website to make improvements to better serve you in
                          the future.{' '}
                        </div>
                      </ul>
                    </div>
                    <hr />
                    <div className="detail_foot" />
                    <a
                      href="https://ec.europa.eu/info/law/law-topic/data-protection/reform/what-personal-data_en"
                      target="_new"
                      className="detail_link"
                    >
                      Click here to view the GDPR definition of Personal Data.
                    </a>
                    <br />
                    <br />
                  </div>
                  <div id="DPT_PL2_XFR" className="collapse">
                    <div className="detail_head">
                      {service.name} stores personal data within the European Union
                      whenever possible. Our principal data centers are located
                      as follows:
                    </div>
                    <hr />
                    <div>
                      <ul>
                        <li className="factor_text">
                          Our major datacenter is located in central Germany.
                        </li>
                        <div
                          id="DPT_PL2_XFR0"
                          style={{"display":"none"}}
                          className="factor_detail"
                        />
                        <li className="factor_text">
                          Our backup datacenter is located in Ireland.
                        </li>
                        <div
                          id="DPT_PL2_XFR1"
                          style={{"display":"none"}}
                          className="factor_detail"
                        />
                      </ul>
                    </div>
                    <hr />
                    <div className="detail_foot">
                      Information Regarding Data Transfers:
                    </div>
                    <a
                      href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/rules-international-transfers-personal-data_en"
                      target="_new"
                      className="detail_link"
                    >
                      We abide by the EU's regulation under GDPR when
                      transferring data.
                    </a>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <br />

          </div>
        </div>
        
      </div>
    );
  }
};

export  default ServicePrivacyPolicySummary;

