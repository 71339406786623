import React from "react"

import { StaticQuery, graphql, Link } from "gatsby"
import Layout from "components/layout.js"
import SEO from "components/seo-component.js"
import DirectoryCategories from "components/content/categories.js"


import ServicePrivacyPolicySummary from '../../../../../plugins/gatsby-source-dpt-services/src/templates/modules/servicePrivacyPolicySummary';  //used as a demo -but may be it's not needed if we have services explore page?

import MarketplaceCTA from 'components/dpt/content/marketplace';

import ServiceRelated from 'components/dpt/content/related';

let Domain = "slack.com"

const PeoplePage = ({ siteTitle, data }) => (
  <StaticQuery
    query={graphql`
      query PeoplePageQuery {
        site {
          siteMetadata {
            title
            url
            blogUrl
            appUrl
            getStartedUrl
            twitterUrl
            linkedInUrl
            feedbackUrl
            description
            homeDescription
            footerDescription
          }
        }
      }
    `}
    render={data => (
      <>
        <Layout>
          <SEO title="For People" />

          <div className="page-header">
            <h2>DataPolicyTrust &amp; You</h2>
            <div className="container">
            {/* <img src={SlackDataPolicyTrustImage}/> */}

            {/* <img data-src="holder.js/500x100?theme=gray"/>  */}
            <p>
              We all rely on the Internet &amp; technology. {' '}
              {/* <br /> */}
              Be more confident &amp; efficient. Take control of your digital life.
            </p>
{/* 
              */}
           <div> 
<div className="container ">
    {/* <h1>{Domain}</h1> */}
    {/* <ServicePrivacyPolicySummary data={data} /> */}
    {(process.env.GATSBY_ACTIVE_ENV=="demo" || process.env.REACT_APP_BUILD_ENV=="demo" || process.env.NODE_ENV=="development" ? 
<div className="row text-left"> 
    
    <div className="col-xs-4 ml-auto mr-auto"><ServicePrivacyPolicySummary qrcode="false" data={{service: { domain: Domain, id: Domain, name: Domain, type: 'website', score: 0}}} /></div>
 
    </div>: <div className="col-xs-auto">

  <img   alt="DataPolicyTrust.com" src="/images/homescreenshot.png" className="center-block  img-responsive mx-auto" />



 </div>)}

{/* 
  <img   alt="DataPolicyTrust.com" src="/images/b2bscreenshot1.png" className="center-block  img-responsive mx-auto" /> */}



    </div>
  </div> 
            
             </div>
          </div>
          <section className="features features-3">  
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-offset-1 col-sm-12 text-center">
                  <h3>
                    Instantly review how personal data is used with each
                    service, product, app, site out there.
                  </h3>
                  {/* <h4>
              We help your team save time by pinpointing unclear and potentially
              risky parameters and information where data privacy and personal
              privacy terms are in user agreements and policy documents.
            </h4> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 text-center feature">
                  <i className="icon-brush-paint-streamline" />
                  <h5>Answers to questions related to privacy</h5>
                  <p>
                    You will learn how your personal privacy parameters relate
                    to the app or service's privacy policies.
                  </p>
                </div>
                <div className="col-md-4 text-center feature">
                  <i className="icon-browser-streamline-window" />
                  <h5>Privacy For The Modern Web, IoT, AI, XR & more</h5>
                  <p>
                    Based on the most modern web standards, devices, platforms,
                    operating systems and eco systems from PC, mobile, IoT, AI,
                    XR &amp; more, highlight how companies handle data
                    protection and trust.
                  </p>
                </div>
                <div className="col-md-4 text-center feature">
                  <i className="icon-keyboard" />
                  <h5>Personal Privacy Freedom</h5>
                  <p>
                    We provide you the most useful and helpful data and
                    information on each app, site and provider to help you
                    evaluate risks &amp; take control of personal and data
                    privacy with the technologies you depend on.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="features features-3">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-offset-1b col-sm-12 text-center">
                  <h3>Why Many People Care about Privacy and Cybersecurity</h3>
                  {/* <h4>
              We help your team save time by pinpointing unclear and potentially
              risky parameters and information where data privacy and personal
              privacy terms are in user agreements and policy documents.
            </h4> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 text-center feature">
                  <i className="icon-bag" />
                  <h5>Your personal data should be private</h5>
                  <p>
                    Not everything online about you is created by you. Your
                    social media profiles contains the information you control.
                    But what matters most is your private information —
                    including home address, phone number and family member's
                    names — available to anyone on public websites that you know
                    nothing about.
                  </p>
                </div>
                <div className="col-md-4 text-center feature">
                  <i className="icon-graph" />
                  <h5>Your personal data affects your life</h5>
                  <p>
                    This data affects your life. It tells friends, colleagues,
                    even strangers, stalkers and thieves where and how you live
                    your life. Because the information exposed about you online
                    affects your life offline.
                  </p>
                </div>
                <div className="col-md-4 text-center feature">
                  <i className="icon-list-thumbnails" />
                  <h5>
                    You should always know what, why, when, where, & how your
                    data is used
                  </h5>
                  <p>
                    You need to control where your private information is
                    exposed and who's accessing it to effectively protect and
                    monitor your reputation, privacy, and identity. We'll show
                    you everything an app or service is doing with your data —
                    and help users monitor the privacy and cyber security health
                    of the service.
                  </p>
                </div>
              </div>
            </div>
            
        
            <div className="row">
          <div className="col-md-12 text-center pb-4 h3">
          <Link to="/why" className="btn btn-primary  btn-sm">Learn Why DataPolicyTrust Exists</Link>
        </div>

        </div>

             
        {process.env.GATSBY_ACTIVE_ENV=="demo" || 
                      process.env.REACT_APP_BUILD_ENV=="demo" || 
                      process.env.NODE_ENV=="development" 
                      ?
                        <>
                    
            <div className="row">
          <div className="col-md-12 text-center pb-4 h3">
          <Link to="/why/whynow" className="btn btn-success  btn-sm">Learn Why Digital Trust Matters for you</Link>
        </div>

        </div>
                      
                      </> : <></>}



          </section>

          <div className="container marketing2">
            {/* <div className="row">
          <div className="col-md-12">
            <div className="mx-auto" styleC="width2:1000px;height:75px">
              <h2 className="text-black font-weight-normal" id="typing-animation">
                We help{' '}
                <span className="text-primary">
                  <span className="u-text-animation u-text-animation--typing" />
                </span>
              </h2>
            </div>
          </div>
        </div> */}
            <div className="row d-none">
              <div className="col-lg-4">
                <i className="icon ion-md-happy" />
                <h2>Confidence</h2>
                {/* <p className="d-none">
              Donec sed odio dui. Etiam porta sem malesuada magna mollis
              euismod. Nullam id dolor id nibh ultricies vehicula ut id elit.
              Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
              Praesent commodo cursus magna.
            </p>
            <p className="d-none">
              <a className="btn btn-secondary" href="#" role="button">
                View details »
              </a>
            </p> */}
              </div>
              <div className="col-lg-4">
                <i className="icon ion-md-lock" />
                <h2>Trust</h2>
                {/* <p className="d-none">
              Duis mollis, est non commodo luctus, nisi erat porttitor ligula,
              eget lacinia odio sem nec elit. Cras mattis consectetur purus sit
              amet fermentum. Fusce dapibus, tellus ac cursus commodo, tortor
              mauris condimentum nibh.
            </p>
            <p className="d-none">
              <a className="btn btn-secondary" href="#" role="button">
                View details »
              </a>
            </p> */}
              </div>
              <div className="col-lg-4">
                <i className="icon ion-md-alert" />
                <h2>Privacy</h2>
                {/* <p className="d-none">
              Donec sed odio dui. Cras justo odio, dapibus ac facilisis in,
              egestas eget quam. Vestibulum id ligula porta felis euismod
              semper. Fusce dapibus, tellus ac cursus commodo, tortor mauris
              condimentum nibh, ut fermentum massa justo sit amet risus.
            </p>
            <p className="d-none">
              <a className="btn btn-secondary" href="#" role="button">
                View details »
              </a>
            </p> */}
              </div>
            </div>
            {/* */}

            {/*  
            <div className="row featurette  d-none2">
              <div className="col-md-7">
                <h2 className="featurette-heading">
                  Browser Warnings. Safer Browsing.{' '}
                  <span className="text-muted" />
                </h2>
                <p className="lead">
                  If you land on a site that has a poor trust, risk, safety,
                  compliance or privacy score, DPT shows you a warning. DPT
                  guides you but the final decision is yours: you can skip the
                  warning and enter the site, or return to the previous page
                </p>
              </div>
              
            </div>
          
            <div className="row featurette  d-none2">
              <div className="col-md-7">
                <h2 className="featurette-heading">
                  Use with trust!{' '}
                  <span className="text-muted">
                    Our team is working hard to monitor all actors - good and
                    bad.
                  </span>
                </h2>
                <p className="lead">
                  With the DPT extension installed, you will see a small icon in
                  your browser’s address bar. The icon shows you the site’s
                  trust score and ratings - green indicates a trustworthy site,
                  yellow signals suspicious, and red indicates danger.
                </p>
              </div>
              
            </div>
           
            <div className="row featurette  d-none2">
              <div className="col-md-7">
                <h2 className="featurette-heading">
                  DPT is your Internet Watchdog. <span className="text-muted" />
                </h2>
                <p className="lead">
                  It’s fast, simple and FREE! Get insights on: Trust scores:
                  links, websites & product websites Reports on policies, terms
                  & user agreements Premium: Monitoring cyber health of
                  providers**
                </p>
              </div>
              
            </div>
           
            <div className="row featurette  d-none2">
              <div className="col-md-7">
                <h2 className="featurette-heading">
                  You can Trust DPT!
                  <span className="text-muted" />
                </h2>
                <p className="lead">
                  Unlike the past, with DPT, you don’t have to worry about us
                  being creepy with your data as we don’t share your PII and
                  browsing data like some others have been known to do.
                </p>
              </div>
              
            </div>{' '} 
           
            <div className="row featurette  d-none2">
              <div className="col-md-7">
                <h2 className="featurette-heading">
                  DPT is a free public service <span className="text-muted" />
                </h2>
                <p className="lead">
                  We help monitor the safety of the Internet when it comes to
                  our shared data privacy. DPT is a free service for everyone
                  with our AI-powered watchdog, guardian and sentinel to watch
                  over the Internet and all good and bad actors who try to cause
                  issues for people.
                </p>
              </div>
              
            </div>
            
            
           
            <div className="row featurette  d-none2">
              <div className="col-md-7">
                <h2 className="featurette-heading">
                  We are better!{' '}
                  <span className="text-muted">
                    We didn't think what was out there really was helping
                    people.
                  </span>
                </h2>
                <p className="lead">
                  Unlike other solutions, with DPT, you don’t have to worry
                  about subjective ratings, opinions or data that may be stale
                  as we provide true monitoring & analytics as well as
                  intelligence and reporting instead of just ‘review’ tools.
                </p>
              </div>
              
            </div>
            */}
            {/* <div className="row featurette">
          <div className="col-md-7 order-md-2">
            <h2 className="featurette-heading">
              Set your own privacy preferences.{' '}
              <span className="text-muted">
                Understand which service providers don't fit your expectations.
              </span>
            </h2>
            <p className="lead">
              For the first time ever, you can now set your own default privacy
              policies and compare which of the services you depend on don't
              really fit with your privacy preferences.
            </p>
          </div>
          <div className="col-md-5 order-md-1">
            <img
              className="featurette-image img-fluid mx-auto"
              data-src="holder.js/500x500/auto"
              alt="500x500"
              src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22500%22%20height%3D%22500%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20500%20500%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_1691155ea84%20text%20%7B%20fill%3A%23AAAAAA%3Bfont-weight%3Abold%3Bfont-family%3AArial%2C%20Helvetica%2C%20Open%20Sans%2C%20sans-serif%2C%20monospace%3Bfont-size%3A25pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_1691155ea84%22%3E%3Crect%20width%3D%22500%22%20height%3D%22500%22%20fill%3D%22%23EEEEEE%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22185.125%22%20y%3D%22261.1%22%3E500x500%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
              data-holder-rendered="true"
              style={{"width":"500px","height":"500px"}}
            />
          </div>
        </div>
        */}

            {/* 
            <main role="main"> 
 
       

        <div className="row featurette">
          <div className="col-md-7">
            <h2 className="featurette-heading">Free Privacy Reporting. <span className="text-muted">Reading each and every policy in your favorite apps is no longer a chore.</span></h2>
            <p className="lead">We provide you with an intelligent simplified data privacy standard that fits the common consumer needs across the globe.  Simplify your understanding of how service providers handle and use your data with scores, ratings and analysis of their policies. Finally a place where you can view privacy confidence of the services you depend on and the apps you love.</p>
          </div>
          
        </div>
 
        <div className="row featurette">
          <div className="col-md-7 order-md-2">
            <h2 className="featurette-heading">Set your own privacy preferences. <span className="text-muted">Understand which service providers don't fit your expectations.</span></h2>
            <p className="lead">For the first time ever, you can now set your own default privacy policies and compare which of the services you depend on don't really fit with your privacy preferences.</p>
          </div>
       
        </div>

        <hr className="featurette-divider"/>

        <div className="row featurette">
          <div className="col-md-7">
            <h2 className="featurette-heading">A central dashboard to track your user agreements. <span className="text-muted">View all the agreements and policies from one dashboard.</span></h2>
            <p className="lead">Finally a place where you can track and review all the sites, apps and services you use that have your data and where you can also track the agreements and policies you consent to.</p>
          </div>
          
        </div>

   
    
      
</main>
 */}
          </div>
{/* 
          <div className="container d-none">
            <div className="features">
              <div className="feature__item">
                <div className="row">
                  <div className="col-6 first">
                    <div className="thumbnail"> 

                      <img alt="" 
                        className="featured"
                        data-src="holder.js/442x245?theme=gray"
                      />
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="feature__content">
                      <h2>Free Privacy Reporting</h2>
                      <p>
                        Reading each and every policy in your favorite apps is
                        no longer a chore. We provide you with an intelligent
                        simplified data privacy standard that fits the common
                        consumer needs across the globe. Simplify your
                        understanding of how service providers handle and use
                        your data with scores, ratings and analysis of their
                        policies. Finally a place where you can view privacy
                        confidence of the services you depend on and the apps
                        you love.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="feature__item">
                <div className="row">
                  <div className="col-6">
                    <div className="feature__content">
                      <h2>
                        A central dashboard to track your user agreements.
                      </h2>
                      <p>
                        View all the agreements and policies from one dashboard.
                        Finally a place where you can track and review all the
                        sites, apps and services you use that have your data and
                        where you can also track the agreements and policies you
                        consent to. A global user privacy and policy dashboard
                        for saved apps, personalized scores, policies,
                        agreements, reporting &amp; alerts on the favorite tech
                        you use.
                      </p>
                    </div>
                  </div>

                  <div className="col-6 first">
                    <div className="thumbnail"> 

                      <img alt="" 
                        className="featured"
                        data-src="holder.js/442x245?theme=gray"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="feature__item">
                <div className="row">
                  <div className="col-6 first">
                    <div className="thumbnail"> 

                      <img
                        className="featured"
                        data-src="holder.js/442x245?theme=gray"
                      />
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="feature__content">
                      <h2>Monitor privacy consequences</h2>
                      <p>
                        Rely on our free data and tools to help you measure,
                        monitor and analyze risks to your privacy in relevant
                        sites, products, services and apps globally.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="feature__item">
                <div className="row">
                  <div className="col-6">
                    <div className="feature__content">
                      <h2>Get started now.</h2>
                      <p>
                        Use our site, browser extensions, & apps whenever you
                        need to search, analyze & save relevant sites, products,
                        services and apps to your dashboard.
                      </p>
                    </div>
                  </div>

                  <div className="col-6 first">
                    <div className="thumbnail"> 

                      <img
                        className="featured"
                        data-src="holder.js/442x245?theme=gray"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="feature__item">
                <div className="row">
                  <div className="col-6 first">
                    <div className="thumbnail"> 

                      <img
                        className="featured"
                        data-src="holder.js/442x245?theme=gray"
                      />
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="feature__content">
                      <h2>Browser Warnings. Safer Browsing.</h2>
                      <p>
                        Our team is working hard to monitor all actors - good
                        and bad. If you land on a site that has a poor trust,
                        risk, safety, compliance or privacy score, DPT shows you
                        a warning. DPT guides you but the final decision is
                        yours: you can skip the warning and enter the site, or
                        return to the previous page. With the DPT extension
                        installed, you will see a small icon in your browser’s
                        address bar. The icon shows you the site’s trust score
                        and ratings - green indicates a trustworthy site, yellow
                        signals suspicious, and red indicates danger.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="feature__item">
                <div className="row">
                  <div className="col-6">
                    <div className="feature__content">
                      <h2>Set your own privacy preferences.</h2>
                      <p>
                        Understand which service providers don't fit your
                        expectations. For the first time ever, you can now set
                        your own default privacy policies and compare which of
                        the services you depend on don't really fit with your
                        privacy preferences.
                      </p>
                    </div>
                  </div>

                  <div className="col-6 first">
                    <div className="thumbnail"> 

                      <img
                        className="featured"
                        data-src="holder.js/442x245?theme=gray"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="feature__item">
                <div className="row">
                  <div className="col-6 first">
                    <div className="thumbnail"> 

                      <img
                        className="featured"
                        data-src="holder.js/442x245?theme=gray"
                      />
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="feature__content">
                      <h2>Make Search better &amp; Safer!</h2>
                      <p>
                        With our Browser Extensions get unbiased warnings, risk
                        scores and recommendations are shown next to links on
                        search engine results, social media platforms, webmail,
                        and other popular sites. You can choose where from the
                        add-on’s setting.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="feature__item">
                <div className="row">
                  <div className="col-6">
                    <div className="feature__content">
                      <h2>Use with trust!</h2>
                      <p>
                        Our team is working hard to monitor all actors - good
                        and bad. With the DPT extension installed, you will see
                        a small icon in your browser’s address bar. The icon
                        shows you the site’s trust score and ratings - green
                        indicates a trustworthy site, yellow signals suspicious,
                        and red indicates danger.
                      </p>
                    </div>
                  </div>

                  <div className="col-6 first">
                    <div className="thumbnail"> 

                      <img
                        className="featured"
                        data-src="holder.js/442x245?theme=gray"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <section className="features features-3 pt-4 pb-4">
            <div className="bg-color2">
              <div className="container">
                <div className="integration-guid">
                  <div className="title">
                    <h2>
                      We provide data on the various digital services and
                      solutions you depend on and many more...
                    </h2>
                    <br />
                    <br />
                  </div>
                  <DirectoryCategories />
                  <br />
                  <br />
            {/* <div className="col-xs-auto border-1">
              <img alt="" 
                src="/images/homescreenshot.png"
                className="center-block  img-responsive mx-auto"
              />
            </div> */}
                    <br />
                    <br />
            {/* <div className="col-xs-auto">
                            <img   alt="DataPolicyTrust.com" src="/images/b2bscreenshot1.png" className="center-block  img-responsive mx-auto" />
                          </div>  */}
                    <br />
                    <br />
                </div>
              </div>
            </div>



            
          </section>
{/*      
          <div className="bg-color">
            <div className="container">
              <div className="integration-guid">
                <div className="title">
                  <h2>How to use DataPolicyTrust for People</h2>
                </div>

                <div className="content">
                  <h4>Overview</h4>
                  <p>As a consumer or end user, you get to use it for free.</p>
                  <h4>Step 1</h4>
                  <p>
                    You search for any product, site, app, service, solution or
                    company that's in our database.
                  </p>
                  

                  <h4>Step 2</h4>
                  <p>
                    You can then view the profile of the company in question and
                    relevant information, tools and data at your finger tips.
                  </p>
                  
                  <h4>Step 3</h4>
                  <p>
                    You can also create an account and save the profile in your
                    dashboard in your account for future reference and to
                    monitor changes to profiles.
                  </p>
                  
                </div>
              </div>
            </div>
          </div> */}

          <div className="call-to-action-dark d-none">
            <div className="container">
              <div className="call-to-action__content">
                <h2>Monitor privacy consequences.</h2>
                <p>
                  Rely on our free data and tools to help you measure, monitor
                  and analyze risks to your privacy in relevant sites, products,
                  services and apps globally with {data.site.siteMetadata.title}
                  .
                </p>
              </div>

              <div className="button">
                <Link
                  to={data.site.siteMetadata.getStartedUrl}
                  // target="_blank"}
                  // rel="noopener noreferrer"
                >
                  Sign Up
                </Link>
              </div>
            </div>
          </div>

          <div className="call-to-action">
            <div className="container">
              <div className="call-to-action__content">
                <h2>Sign up for free</h2>
                <p>
                  Sign up to start improving your digital world with {data.site.siteMetadata.title}.
                </p>
              </div>

              <div className="button">
                <Link
                  to={data.site.siteMetadata.getStartedUrl}
                  // target="_blank"}
                >
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        </Layout>
      </>
    )}
  />
)

export default PeoplePage
